import { UpcSelectionErrorType } from '../models/enums';
import { IError } from '../models/responses';

export const getErrorTitle = (errorType: string) => {
  switch (errorType) {
    case UpcSelectionErrorType.SecondaryUpc:
      return 'Secondary UPC.';
    case UpcSelectionErrorType.UpcDiscontinued:
      return 'UPC discontinued.';
    case UpcSelectionErrorType.UpcNotAuthorized:
      return 'UPC not authorized for any supplier.';
    case UpcSelectionErrorType.UpcNotFound:
      return 'UPC not found.';
    case UpcSelectionErrorType.OrderCodeNotFound:
      return 'Order Code not found.';
    default:
      return 'An unknown error has occurred.';
  }
};

export const getErrorMessage = (error?: IError) => {
  switch (error?.type) {
    case UpcSelectionErrorType.SecondaryUpc:
      return error.message ?? 'Secondary UPC.';
    case UpcSelectionErrorType.UpcDiscontinued:
    case UpcSelectionErrorType.UpcNotAuthorized:
    case UpcSelectionErrorType.UpcNotFound:
    case UpcSelectionErrorType.OrderCodeNotFound:
      return ''; //show title only, per mockup
    default:
      return 'An unknown error has occured.';
  }
};
