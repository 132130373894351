/* eslint-disable no-console */
import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { IContract, IContractTermForItem, IContractTermForLumpSum, IContractItem, IPricing, SystemAlert } from '../../../models';
import { DbgButtonSize, Permission } from '../../../models/enums';
import { getGreenButtonStyleProps } from '../../../styles/themes';
import { DbgRoundedButton } from '../../components/buttons/DbgRoundedButton';
import { RouteEnum } from '../../layout/PageRouter';
import NewContractModal from '../Contract/components/dialogs/NewContractModal';
import ContractsDataGrid from './components/ContractsDataGrid';
import PermissionFilter from '../../components/shared/PermissionFilter';
import PageHeader from '../../layout/components/PageHeader';
import PageBody from '../../layout/components/PageBody';
import { systemAlertService } from '../../../services';

export default function DashboardPage() {
  const navigate = useNavigate();

  const [newContractModalOpen, setNewContractModalOpen] = useState(false);
  const [showSystemAlert, setShowSystemAlert] = useState(false);
  const [systemAlert, setSystemAlert] = useState<SystemAlert | null>(null);

  useEffect(() => {
    const fetchAlertStatus = async () => {
      try {
        const systemResponse = await systemAlertService.getSystemAlerts();
        if (systemResponse.enabled) {
          setShowSystemAlert(true);
          setSystemAlert(systemResponse);
        }
      } catch (error) {
        console.error('Failed to fetch alert status', error);
      }
    };

    fetchAlertStatus();
  }, []);

  const onContractModalContinue = (contract: IContract) => {
    setNewContractModalOpen(false);
    navigate(RouteEnum.Contract, { state: { contract: contract } });
  };

  const onNewContractModalCancel = () => {
    setNewContractModalOpen(false);
  };

  const defaultInitialContract = {
    contractId: 0,
    createdAtUtc: new Date(),
    terms: {
      contractTermsForLumpSum: [] as IContractTermForLumpSum[],
      contractTermsForItem: [] as IContractTermForItem[],
      isProposed: false,
      includeAllStores: true,
      autoAddAssociatedItems: false,
      vendorContractNumber: '',
      productLine: '',
      contractItems: [] as IContractItem[],
      stores: { storeIds: [], storeGroupIds: [] },
    },
    pricing: [] as IPricing[],
  } as IContract;

  return (
    <>
      <PageHeader />
      {showSystemAlert && systemAlert && (
        <Box
          sx={{
            ...styles.systemAlert,
            backgroundColor: systemAlert?.backgroundColorHex || 'inherit',
            color: systemAlert?.textColorHex || 'inherit',
          }}
        >
          {systemAlert.messageText}
        </Box>
      )}
      <PageBody>
        <Box sx={styles.root}>
          <Box sx={styles.titleRow}>
            <Box sx={styles.title}>Contracts</Box>
            <PermissionFilter permission={Permission.TermsCreate}>
              <DbgRoundedButton
                id="NewContractButton"
                dbgButtonSize={DbgButtonSize.Large}
                styleProps={getGreenButtonStyleProps()}
                sx={styles.newContractBtn}
                onClick={() => {
                  setNewContractModalOpen(true);
                }}
              >
                + New Contract
              </DbgRoundedButton>
            </PermissionFilter>
          </Box>
          <PermissionFilter permission={Permission.TermsView} error="Not authorized to view contracts.">
            <ContractsDataGrid />
          </PermissionFilter>
          {newContractModalOpen && (
            <NewContractModal
              onContinue={onContractModalContinue}
              onCancel={onNewContractModalCancel}
              initialContract={defaultInitialContract}
            ></NewContractModal>
          )}
        </Box>
      </PageBody>
    </>
  );
}

const styles = {
  root: {
    width: '100%',
    paddingX: '5%',
    marginTop: '30px',
  },
  title: {
    fontSize: '35px',
    marginRight: '14px',
  },
  titleRow: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginBottom: '26px',
  },
  newContractBtn: {
    width: '153px',
    fontWeight: 500,
  },
  systemAlert: {
    width: '100%',
    color: 'white',
    backgroundColor: 'red',
    height: '30px',
    textAlign: 'center',
    fontSize: '13px',
    fontFamily: 'Inter, Poppins, sans-serif',
    fontWeight: 600,
    paddingTop: '6px',
  },
};
