import { ValidationErrorType } from '../models/enums';

const FieldName = (param: IValidationErrorParam): string => param.displayField ?? param.field ?? 'Field';

export interface IValidationErrorParam {
  message?: string;
  field?: string;
  displayField?: string;
  identifier?: string;
  errorType?: ValidationErrorType;
}

export interface IValidationErrorComparerParam extends IValidationErrorParam {
  input?: string;
  comparison?: string;
}

export class ValidationError {
  public message: string;
  public field?: string;
  public fullField?: string;
  public identifier?: any;
  public errorType: ValidationErrorType;
  public displayField?: string;

  constructor(param: IValidationErrorParam) {
    if (!param.message) throw new Error('Message is required for ValidationError');

    this.message = param.message;
    this.field = param.field;
    this.identifier = param.identifier;
    this.errorType = param.errorType ?? ValidationErrorType.Error;
    this.displayField = param.displayField;
  }

  ToString(): string {
    if (!this.displayField && !this.field) return this.message;
    return `${this.displayField ?? this.field}: ${this.message}`;
  }

  ScopeField(parentFieldName?: string): this {
    if (!parentFieldName) return this;

    if (this.field) {
      this.fullField = `${parentFieldName}.${this.field}`;
    } else {
      this.field = parentFieldName;
      this.fullField = parentFieldName;
    }
    return this;
  }

  static GreaterThan(param: IValidationErrorComparerParam) {
    return new ValidationError({ ...param, message: `${param.input} must be greater than ${param.comparison}.` });
  }

  static GreaterThanOrEqual(param: IValidationErrorComparerParam) {
    return new ValidationError({ ...param, message: `${param.input} must be greater than or equal to ${param.comparison}.` });
  }

  static LessThan(param: IValidationErrorComparerParam) {
    return new ValidationError({ ...param, message: `${param.input} must be less than ${param.comparison}.` });
  }

  static LessThanOrEqual(param: IValidationErrorComparerParam) {
    return new ValidationError({ ...param, message: `${param.input} must be less than or equal to ${param.comparison}.` });
  }

  static NonEmpty(param: IValidationErrorParam) {
    return new ValidationError({ ...param, message: `${FieldName(param)} must not be empty.` });
  }

  static NotFound(param: IValidationErrorParam) {
    return new ValidationError({ ...param, message: `${FieldName(param)} was not found.` });
  }

  static Required(param: IValidationErrorParam) {
    return new ValidationError({ ...param, message: `${FieldName(param)} is required.` });
  }
}
