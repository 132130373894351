import { inputClasses, dialogClasses, dialogTitleClasses, dialogContentClasses, dialogActionsClasses } from '@mui/material';
import { defaultColors, defaultBorders } from '../../../../../../styles/variables';

const styles = {
  formRow: {
    marginBottom: '24px',
    width: '100%',
    minHeight: '60px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  formFields: {
    vendorContractNumber: {
      width: '100%',
    },
    comments: {
      width: '100%',
    },
    customer: [
      {
        width: '50%',
        [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
          height: 60,
          borderTopRightRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
        },
      },
    ],
    customerName: {
      width: '50%',
      borderLeft: 'none !important',
      borderTopLeftRadius: '0px !important',
      borderBottomLeftRadius: '0px !important',
      backgroundColor: defaultColors.lightGrey,
      paddingRight: '10px',
      '&:hover': {
        border: `1px solid ${defaultColors.border}`,
        boxShadow: defaultBorders.boxShadow,
      },
      [`& .${inputClasses.input}.Mui-disabled`]: {
        textFillColor: defaultColors.darkGrey,
      },
    },
    manufacturer: [
      {
        width: '50%',
        [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
          height: 60,
          borderTopRightRadius: '0px !important',
          borderBottomRightRadius: '0px !important',
        },
      },
    ],

    productLine: {
      width: '50%',
      // borderLeft: 'none',
      borderLeft: 'none !important',
      borderTopLeftRadius: '0px !important',
      borderBottomLeftRadius: '0px !important',
      paddingRight: '10px',
    },
    categoryManager: [
      {
        width: 242,
        [`& .MuiFormControl-root.MuiFormControl-fullWidth`]: {
          height: 60,
        },
      },
    ],
  },
  dbgDialogOverrides: {
    [`& .${dialogClasses.paper}`]: {
      minWidth: '620px',
      paddingX: '60px',
    },
    [`& .${dialogTitleClasses.root}`]: {
      borderBottom: 'unset',
      marginTop: '45px',
      marginBottom: '25px',
      padding: '0px',
      fontSize: '25px',
    },
    [`& .${dialogContentClasses.root}`]: {
      padding: 'unset',
      overflow: 'visible', // required for input box focus
    },
    [`& .${dialogActionsClasses.root}`]: {
      borderTop: 'unset',
      padding: 'unset',
      marginBottom: '64px',
    },
  },
};

export const NewContractModalStyles = {
  styles,
};
