import { IUpcSearchResponse } from '../models/responses';
import { HttpErrorResponse, fetchGetJson } from './contractHubApi';

export const itemService = {
  searchByUpc,
  searchByOrderCode,
};

function searchByUpc(searchValue: number): Promise<IUpcSearchResponse | HttpErrorResponse> {
  return fetchGetJson<IUpcSearchResponse>({ endpoint: 'item', query: { upc: searchValue } });
}

function searchByOrderCode(searchValue: string): Promise<IUpcSearchResponse | HttpErrorResponse> {
  return fetchGetJson<IUpcSearchResponse>({ endpoint: 'item', query: { code: searchValue } });
}
