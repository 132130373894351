import { Box, Button, Tooltip } from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import { useStyles } from 'tss-react';
import { IContract, IContractItem, IContractTermForItem, IContractTerms, IItem, IStoreSelection, ISupplier } from '../../../../models';
import { DbgLinkButton } from '../../../components/buttons/DbgLinkButton';
import { useApplicationContextState } from '../../../../contexts/ApplicationContext';
import { DbgButtonSize, SortDirection } from '../../../../models/enums';
import { getRedButtonStyleProps, getTransparentButtonStyleProps, getWhiteButtonStyleProps } from '../../../../styles/themes';
import { DbgRoundedButton } from '../../../components/buttons/DbgRoundedButton';
import { format } from 'date-fns';
import UpcSelectionModal from './dialogs/UpcSelectionModal';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteIcon from '../../../../images/delete.svg';
import { StyledImg } from '../../../../styles/styled/StyledWrappedCommonElements';
import { UpcDataGridStyles } from './styles/UpcDataGridStyles';
import { defaultColors } from '../../../../styles/variables';
import { ArrayUtils } from '../../../../utilities/ArrayUtility';
import { DialogSharedStyles } from '../styles/DialogSharedStyles';
import DbgDialog from '../../../components/dialogs/DbgDialog';
import OverlappingContractsButton from './OverlappingContractsButton';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ScheduleIcon from '@mui/icons-material/Schedule';
import {
  DataGrid,
  DataGridColumnVisibilityMap,
  DataGridColDef,
  DataGridRenderBodyCellParams,
  DataGridValueGetterParams,
  DataGridValueSetterParams,
  DataGridEditableCellOverrideParams,
  DataGridSortDirection,
  NumberInput,
  Checkbox,
} from '@dierbergs-markets/react-component-library';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import RefreshIcon from '../../../../images/refresh.svg';
import { ValidationError } from '../../../../classes/ValidationError';
import { NumberToCurrencyString } from '../../../../utilities/NumberUtility';
import { v4 } from 'uuid';
import { useContractShowErrorsStore } from '../../../../store';
import SupplierToolTip from '../../../../views/components/shared/SupplierToolTip';
type FixedColumnNames = 'caseListCost' | 'suggestedRetailPrice' | 'suggestedRetailMultiple';

interface IProps {
  contract: IContract;
  contractTerms: IContractTerms;
  storeSelections: IStoreSelection;
  canModify: boolean;
  onTermEdit: (term: IContractTermForItem) => void;
  onContractItemsChange: (contractItems: IContractItem[]) => void;
  onAutoIncludeAssociatedItemsCheck: (checked: boolean) => void;
  errors?: ValidationError[];
  contractLoading: boolean;
}

const UpcDataGrid = (props: IProps) => {
  const LOCALSTORAGE_COLUMN_VISIBILITY = 'upcDataGrid.preferences.colVisibility';

  const mounted = useRef(true);
  const { referenceData } = useApplicationContextState();

  const { styles } = UpcDataGridStyles;
  const { styles: dlgStyles } = DialogSharedStyles;

  const { css } = useStyles();

  const invokeTermEdit = (term: IContractTermForItem): void => {
    props.canModify && props.onTermEdit(term);
  };

  useEffect(() => {
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (props.contractTerms.stores.storeGroupIds && props.contractTerms.stores.storeGroupIds.length > 0 && referenceData?.storeGroups) {
      const refGroup = referenceData.storeGroups[props.contractTerms.stores.storeGroupIds[0]];
      if (refGroup) {
        setStoresOnContract(refGroup.storeNumbers);
      }
    } else {
      setStoresOnContract(props.contractTerms.stores.storeIds);
    }
  }, [props.contractTerms.stores.storeGroupIds, props.contractTerms.stores.storeIds]);

  const [showUpcSelectionModal, setShowUpcSelectionModal] = useState(false);
  const [showDeleteContractItemConfirmation, setShowDeleteContractItemConfirmation] = useState<boolean>(false);
  const [contractItemToDelete, setContractItemToDelete] = useState<number | undefined>();
  const { showErrors } = useContractShowErrorsStore();
  const [storesOnContract, setStoresOnContract] = useState<number[]>();

  const defaultVisiblityMap: DataGridColumnVisibilityMap = getGridColumns().reduce((acc, cur, index) => {
    const fieldName = cur.field || `column_${index}`;

    if (cur.hideable === false) return acc;

    let isVisible = true;

    const fieldsHiddenByDefault = ['item.priceAssociation', 'item.department'];

    if (fieldsHiddenByDefault.includes(fieldName)) isVisible = false;

    return { ...acc, [fieldName]: isVisible };
  }, {});

  const rawVisibilityMap = localStorage.getItem(LOCALSTORAGE_COLUMN_VISIBILITY);
  const [visibilityMap, setVisibilityMap] = useState<DataGridColumnVisibilityMap>(
    rawVisibilityMap ? JSON.parse(rawVisibilityMap) : defaultVisiblityMap
  );

  function getGridColumns(): DataGridColDef[] {
    if (!referenceData) return [];

    function isCellDisabled(termUnitOfMeasureId: number, isRandomWeight?: boolean) {
      return (
        (referenceData?.termTypeUnitsOfMeasure[termUnitOfMeasureId].forRandomWeight && !isRandomWeight) ||
        (!referenceData?.termTypeUnitsOfMeasure[termUnitOfMeasureId].forRandomWeight && isRandomWeight)
      );
    }

    function setEmptyColumnInputs(promoIndexPosition?: number, value?: number, fixedColumn?: FixedColumnNames) {
      if (value === undefined || isNaN(value)) return;
      if (props.contractTerms.contractItems.length === 0) return;

      const updatedContractItems = props.contractTerms.contractItems.map((ci) => {
        if (promoIndexPosition !== undefined && (ci.amounts[promoIndexPosition] === null || ci.amounts[promoIndexPosition] === undefined)) {
          const term = props.contractTerms.contractTermsForItem[promoIndexPosition];
          if (!isCellDisabled(term.termUnitOfMeasureId, ci.item?.isRandomWeight)) {
            return { ...ci, amounts: replaceValue(ci.amounts, promoIndexPosition, value) };
          }
        } else if (fixedColumn) {
          if (!ci[fixedColumn] && ci[fixedColumn] !== 0) {
            return { ...ci, [fixedColumn]: value };
          }
        }

        return ci;
      });

      props.onContractItemsChange(updatedContractItems);
    }

    function replaceValue<T>(array: T[], index: number, value: T): T[] {
      const updated = [...array];
      updated.splice(index, 1, value);
      return updated;
    }

    const CreateDateHeader = (promo: IContractTermForItem) => {
      if (!promo.startDate || !promo.endDate) {
        return (
          <DbgLinkButton id={`PromoMissingDates${promo.uniqueId}`} onClick={() => invokeTermEdit(promo)} sx={styles.promoMissingDates}>
            <ErrorOutlineIcon sx={styles.promoMissingDatesIcon} /> Missing Date(s)
          </DbgLinkButton>
        );
      }

      if (
        props.errors?.find(
          (q) => (q.field === 'ContractTermsForItem.StartDate' || q.field === 'ContractTermsForItem.EndDate') && q.identifier === promo.uniqueId
        )
      ) {
        return (
          <DbgLinkButton id={`PromoInvalidDates${promo.uniqueId}`} onClick={() => invokeTermEdit(promo)} sx={styles.promoMissingDates}>
            <ErrorOutlineIcon sx={styles.promoMissingDatesIcon} /> Invalid Date(s)
          </DbgLinkButton>
        );
      }

      return `${format(new Date(promo.startDate), 'MM/dd/yy')} - ${format(new Date(promo.endDate), 'MM/dd/yy')}`;
    };

    const getUpcCellClasses = (x: any) => {
      const classes: any[] = [styles.columnOverrides.itemBodyCell];
      const fieldError = !!props.errors?.find(
        (q) => q.field?.toLowerCase() === x.field.toLowerCase() && q.identifier === x.row.uniqueId && !x.row.isChildRow
      );
      if (fieldError && showErrors) classes.push(styles.columnOverrides.promoBodyCellError);
      return classes;
    };

    const upcImageStyle = { width: '16px', height: '18px', marginLeft: '5px', marginRight: '5px' };
    const generalInfoColumns: DataGridColDef[] = [
      {
        headerName: '',
        field: 'delete',
        type: 'string',
        width: 40,
        tabIndex: -1,
        hideable: false,
        columnBodyCss: { ...styles.columnOverrides.deleteBodyCell, ...styles.columnOverrides.itemBodyCell },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number>) => {
          if (!props.canModify || params.row.isShipperContainedItem) return <></>;
          return (
            <Button
              data-button-type={'delete'}
              sx={styles.buttons.deleteContractItem}
              id={`deleteContractItem_${params.row.sku}`}
              onClick={() => handleContractItemDelete(params.row.sku)}
              tabIndex={-1}
            >
              <StyledImg src={DeleteIcon} sx={styles.buttons.deleteContractItem.icon} />
            </Button>
          );
        },
      },
      {
        headerName: `Item UPC (${props.contractTerms.contractItems.length})`,
        field: 'item.upc',
        type: 'string',
        width: 145,
        tabIndex: -1,
        columnBodyCss: getUpcCellClasses,
        sortable: true,
        hideable: false,
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number>) => {
          if (!params.value) return 'Unknown Id';
          const discontinued = params.row.item?.discontinuedDate && params.row.item?.discontinuedDate < new Date();
          return (
            <Box sx={{ display: 'flex', alignItems: 'middle' }}>
              {params.value} {!params.row.isSystemAdded ? <></> : <StyledImg src={RefreshIcon} sx={upcImageStyle} />}
              {discontinued ? (
                <Tooltip title="Discontinued">
                  <ScheduleIcon sx={upcImageStyle} />
                </Tooltip>
              ) : (
                <></>
              )}
            </Box>
          );
        },
      },
      {
        headerName: 'Department',
        field: 'item.department',
        type: 'string',
        width: 120,
        tabIndex: -1,
        sortable: true,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
      },
      {
        headerName: 'Price Association',
        field: 'item.priceAssociation',
        type: 'number',
        width: 140,
        tabIndex: -1,
        sortable: true,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
      },
      {
        headerName: 'Supplier(s) #',
        type: 'string',
        field: 'suppliers.id',
        width: 100,
        sortable: false,
        hideable: true,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
        renderBodyCellContent: (x) => {
          if (!x.row.item) return 'N/A';
          const supplierArr: ISupplier[] = x.row.item.suppliers.filter((supplier: ISupplier) => {
            return supplier.storeNumbers.some((storeNumber) => storesOnContract?.includes(storeNumber));
          });
          if (supplierArr && Array.isArray(supplierArr) && supplierArr.length > 1) {
            return <SupplierToolTip supplierArr={supplierArr} />;
          } else if (supplierArr && supplierArr.length === 1) {
            return supplierArr[0].id;
          } else {
            return <></>;
          }
        },
      },
      {
        headerName: 'Supplier Name',
        type: 'string',
        field: 'suppliers.name',
        width: 200,
        sortable: false,
        hideable: true,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
        renderBodyCellContent: (x) => {
          if (!x.row.item) return 'N/A';
          const supplierArr: ISupplier[] = x.row.item.suppliers.filter((supplier: ISupplier) => {
            return supplier.storeNumbers.some((storeNumber) => storesOnContract?.includes(storeNumber));
          });
          return supplierArr && supplierArr.length === 1 ? supplierArr[0].name : <></>;
        },
      },
      {
        headerName: 'Pack',
        type: 'string',
        field: 'item.quantityPerPack',
        width: 70,
        tabIndex: -1,
        sortable: true,
        hideable: false,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
      },
      {
        headerName: 'Size',
        type: 'string',
        field: 'item.size',
        width: 75,
        tabIndex: -1,
        sortable: true,
        hideable: false,
        columnBodyCss: styles.columnOverrides.itemBodyCell,
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<IItem, IContractItem>) => {
          return <div>{`${params.value} ${params.row.item?.unitOfMeasure ? params.row.item?.unitOfMeasure?.toLowerCase() : ''}`}</div>;
        },
      },
      {
        headerName: 'Description',
        type: 'string',
        field: 'item.description',
        flex: 1,
        minWidth: 250,
        tabIndex: -1,
        hideable: false,
        sortable: true,
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<string, IContractItem>) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
              {params.collapsible && (
                <div
                  onClick={() => {
                    if (params.setCollapsed) params.setCollapsed(!params.isCollapsed);
                  }}
                >
                  {params.isCollapsed ? (
                    <KeyboardArrowDownIcon sx={{ color: defaultColors.blue }} />
                  ) : (
                    <KeyboardArrowUpIcon sx={{ color: defaultColors.blue }} />
                  )}
                </div>
              )}
              <Box
                style={{
                  marginLeft: params.row.isShipperContainedItem ? '20px' : '0px',
                  overflowX: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexGrow: 100,
                  fontWeight: 500,
                  color: params.collapsible ? defaultColors.blue : defaultColors.black,
                }}
                title={params.value}
              >
                {params.value}
              </Box>
            </Box>
          );
        },
      },
    ];

    const getCellClasses = (x: any) => {
      const classes: any[] = [styles.columnOverrides.promoBodyCell];
      const fieldError = !!props.errors?.find(
        (q) => q.field?.toLowerCase() === x.field.toLowerCase() && q.identifier === x.row.uniqueId && !x.row.isChildRow
      );
      if (fieldError && showErrors) classes.push(styles.columnOverrides.promoBodyCellError);
      return classes;
    };

    const inputColumns: DataGridColDef[] = [
      {
        headerName: 'Case List Cost',
        field: 'caseListCost',
        type: 'number',
        width: 150,
        editable: props.canModify,
        findReplaceEnabled: true,
        hideable: false,
        columnBodyCss: getCellClasses,
        columnHeaderCss: {
          root: styles.columnOverrides.headerInputCell,
        },
        renderHeaderCellContent: () => {
          return (
            <Box sx={styles.headerInputAndLabelContainer}>
              <Box sx={styles.headerNumberInputLabel}>Case List Cost</Box>
              {props.canModify && (
                <NumberInput
                  id={`caseListCostHeaderInput`}
                  classes={{
                    root: css(styles.headerNumberInputRoot),
                  }}
                  onChangeCompleted={(value: any) => setEmptyColumnInputs(undefined, value, 'caseListCost')}
                  clearOnEnter
                  clearOnBlur
                  decimals={2}
                  prefix="$"
                  tabIndex={-1}
                />
              )}
            </Box>
          );
        },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number | undefined>) => {
          if (params.row.isShipperContainedItem) {
            return <></>;
          }
          let amount: number | undefined = undefined;
          if (params.value != undefined && params.value.toString() != '') amount = parseFloat(`${params.value}`);

          return CreateInputBodyCell(amount);
        },
      },
    ];

    const promosColumns: DataGridColDef[] =
      props.contractTerms.contractTermsForItem.map((promo, promoIndex) => {
        function commonGetter(params: DataGridValueGetterParams<IContractItem>) {
          if (isCellDisabled(promo.termUnitOfMeasureId, params.row.item?.isRandomWeight)) {
            return 0;
          }
          if (params.row.isShipperContainedItem) {
            return 0;
          }
          return params.row.amounts[promoIndex];
        }

        function commonSetter(params: DataGridValueSetterParams<number | undefined, IContractItem>) {
          let value: number | undefined = undefined;
          if (params.value != undefined && params.value.toString() != '') {
            value = parseFloat(`${params.value}`);
          }

          if (isCellDisabled(promo.termUnitOfMeasureId, params.row.item?.isRandomWeight)) {
            value = 0;
          }

          const amounts = [...params.row.amounts];

          if (amounts[promoIndex] === value) return params.row;
          amounts[promoIndex] = value;
          return { ...params.row, amounts };
        }

        return {
          headerName: promo?.termTypeId ? referenceData.termTypes[promo.termTypeId].name : '',
          findReplaceEnabled: true,
          field: `promo_${promo?.termTypeId || 0}_${promoIndex}`,
          type: 'number',
          width: 195,
          editable: props.canModify,
          hideable: false,
          columnBodyCss: getCellClasses,
          columnHeaderCss: {
            root: styles.columnOverrides.headerInputCell,
          },
          renderHeaderCellContent: () => {
            const termTypeBox = (
              <>
                <Box sx={styles.buttons.promoModal.container}>
                  <Box data-label={'termType'}>
                    {props.canModify && <EditOutlinedIcon sx={styles.buttons.promoModal.editIcon} />}
                    {promo.termTypeId && referenceData.termTypes[promo.termTypeId].name}
                  </Box>
                </Box>
                <Box sx={styles.buttons.promoModal.uom} data-label={'uom'}>
                  {promo.termUnitOfMeasureId && `By ${referenceData.termTypeUnitsOfMeasure[promo.termUnitOfMeasureId].name}`}
                </Box>
              </>
            );
            return (
              <>
                <Box
                  key={promoIndex}
                  sx={{
                    paddingTop: '16px',
                    width: '100%',
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    marginLeft: '6px',
                  }}
                >
                  {(props.canModify && (
                    <DbgLinkButton
                      id={`UpcGridPromotion${promo.uniqueId}`}
                      sx={[styles.rightAlign, styles.buttons.promoModal, styles.buttons.promoModal.editable]}
                      onClick={() => invokeTermEdit(promo)}
                    >
                      {termTypeBox}
                    </DbgLinkButton>
                  )) || <Box sx={[styles.rightAlign, styles.buttons.promoModal]}>{termTypeBox}</Box>}
                  <Box sx={styles.promoInputAndLabelWrapper}>
                    <Box sx={[styles.headerNumberInputLabel, styles.promoHeaderDates]}>&nbsp;&nbsp;{CreateDateHeader(promo)}</Box>

                    {props.canModify && (
                      <NumberInput
                        id={`termAmountHeaderInput_${promoIndex}`}
                        classes={{
                          root: css(styles.headerNumberInputRoot),
                        }}
                        onChangeCompleted={(value: any) => setEmptyColumnInputs(promoIndex, value)}
                        clearOnEnter
                        clearOnBlur
                        decimals={2}
                        prefix="$"
                        tabIndex={-1}
                      />
                    )}
                  </Box>
                </Box>
              </>
            );
          },
          renderBodyCellContent: (params: DataGridRenderBodyCellParams<number | null, IContractItem>) => {
            if (params.row.isShipperContainedItem) {
              return <></>;
            }
            if (isCellDisabled(promo?.termUnitOfMeasureId, params.row.item?.isRandomWeight)) {
              return CreateDisabledBodyCell();
            }
            const amount: number | undefined = params.row.amounts[promoIndex];
            return CreateInputBodyCell(amount);
          },
          editableCellOverride: (params: DataGridEditableCellOverrideParams<number, IContractItem>) => {
            //cell is editable if it is not disabled
            return { isEditable: !isCellDisabled(promo.termUnitOfMeasureId, params.row.item?.isRandomWeight) };
          },

          valueGetter: commonGetter,
          valueSetter: commonSetter,
          findValueOverride: commonGetter,
          replaceValueOverride: commonSetter,
        };
      }) || [];

    const srpColumns: DataGridColDef[] = [
      {
        headerName: 'Suggested Retail Multiple',
        findReplaceEnabled: true,
        field: 'suggestedRetailMultiple',
        type: 'number',
        width: 105,
        editable: props.canModify,
        decimalPlaces: 0,
        hideable: false,
        columnBodyCss: getCellClasses,
        columnHeaderCss: {
          root: styles.columnOverrides.headerInputCell,
        },
        suffix: '/',
        prefix: '',
        renderHeaderCellContent: () => {
          return (
            <Box sx={styles.headerInputAndLabelContainer}>
              <Box sx={styles.headerNumberInputLabel}>Multiple</Box>
              {props.canModify && (
                <NumberInput
                  id={`suggestedRetailMultipleHeaderInput`}
                  classes={{
                    root: css(styles.headerNumberInputRoot),
                  }}
                  onChangeCompleted={(value: any) => setEmptyColumnInputs(undefined, value, 'suggestedRetailMultiple')}
                  clearOnEnter
                  clearOnBlur
                  decimals={0}
                  allowLeadingZeros={false}
                  suffix="/"
                  tabIndex={promosColumns?.length ?? 0}
                />
              )}
            </Box>
          );
        },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number | undefined>) => {
          if (params.row.isShipperContainedItem) {
            return <></>;
          }
          let amount: number | undefined = undefined;
          if (params.value != undefined && params.value.toString() != '') amount = parseInt(`${params.value}`);

          return CreateInputBodyIntCell(amount);
        },
      },
      {
        headerName: 'Suggested Retail Price',
        findReplaceEnabled: true,
        field: 'suggestedRetailPrice',
        type: 'number',
        width: 195,
        editable: props.canModify,
        hideable: false,
        columnBodyCss: getCellClasses,
        columnHeaderCss: {
          root: styles.columnOverrides.headerInputCell,
        },
        renderHeaderCellContent: () => {
          return (
            <Box sx={styles.headerInputAndLabelContainer}>
              <Box sx={styles.headerNumberInputLabel}>Suggested Retail Price</Box>
              {props.canModify && (
                <NumberInput
                  id={`suggestedRetailPriceHeaderInput`}
                  classes={{
                    root: css(styles.headerNumberInputRoot),
                  }}
                  onChangeCompleted={(value: any) => setEmptyColumnInputs(undefined, value, 'suggestedRetailPrice')}
                  clearOnEnter
                  clearOnBlur
                  decimals={2}
                  prefix="$"
                  tabIndex={promosColumns?.length ?? 0 + 1}
                />
              )}
            </Box>
          );
        },
        renderBodyCellContent: (params: DataGridRenderBodyCellParams<number | undefined>) => {
          if (params.row.isShipperContainedItem) {
            return <></>;
          }
          let amount: number | undefined = undefined;
          if (params.value != undefined && params.value.toString() != '') amount = parseFloat(`${params.value}`);

          return CreateInputBodyCell(amount);
        },
      },
    ];

    return [...generalInfoColumns, ...inputColumns, ...promosColumns, ...srpColumns];
  }

  function handleUpcAdded(contractItems: IContractItem[]) {
    setShowUpcSelectionModal(false);
    const addedItems = contractItems.map((ci) => {
      return {
        ...ci,
        caseListCost: undefined,
        suggestedRetailPrice: undefined,
        suggestedRetailMultiple: undefined,
        amounts: (ci.amounts = Array<number | undefined>(props.contractTerms.contractTermsForItem.length).fill(undefined)), //predefine empty array
        uniqueId: ci.uniqueId ?? v4(),
        childData: ci.childData
          ? ci.childData.map((child) => ({
              ...child,
              caseListCost: undefined,
              suggestedRetailPrice: undefined,
              suggestedRetailMultiple: undefined,
              amounts: [undefined],
              uniqueId: child.uniqueId ?? v4(),
            }))
          : [],
      };
    });
    const distinctItems = ArrayUtils.distinct([...props.contractTerms.contractItems, ...addedItems], (a, b) => a.sku === b.sku);
    props.onContractItemsChange(distinctItems);
  }

  function handleContractItemDelete(sku: number): void {
    setContractItemToDelete(sku);
    setShowDeleteContractItemConfirmation(true);
  }

  function handleContractItemModalDeleteConfirm() {
    if (!contractItemToDelete) return;
    const items = [...props.contractTerms.contractItems.filter((i) => i.sku !== contractItemToDelete)];
    props.onContractItemsChange(items);
    setShowDeleteContractItemConfirmation(false);
  }

  function handleContractItemRowUpdate(updatedRow: IContractItem) {
    const items = props.contractTerms.contractItems.map((ci) => (ci.sku === updatedRow.sku ? updatedRow : ci));

    props.onContractItemsChange(items);
  }

  function handleContractItemsRowUpdate(rows: IContractItem[]) {
    props.onContractItemsChange(
      props.contractTerms.contractItems.map((ci) => {
        const row = rows.find((item) => item.sku === ci.sku);
        if (row && !row.isShipperContainedItem) {
          return row;
        } else {
          return {
            ...ci,
            childData: ci.childData?.map((a) => {
              const row = rows.find((item) => item.sku === a.sku);
              return row ? row : a;
            }),
          };
        }
      })
    );
  }

  function handleColumnVisibilityChange(map: DataGridColumnVisibilityMap) {
    setVisibilityMap(map);
    localStorage.setItem(LOCALSTORAGE_COLUMN_VISIBILITY, JSON.stringify(map));
  }
  return (
    <>
      <Box sx={styles.root}>
        <Box sx={styles.actionsContainer}>
          <Box sx={styles.actions}>
            {props.canModify && (
              <DbgRoundedButton
                id="AddUpc"
                dbgButtonSize={DbgButtonSize.Large}
                styleProps={getTransparentButtonStyleProps()}
                sx={styles.buttons.addUpc}
                onClick={() => setShowUpcSelectionModal(true)}
              >
                + New Item
              </DbgRoundedButton>
            )}
            <Checkbox
              id={'AutoAddItems'}
              label={'Include items in the same price association'}
              checked={props.contractTerms.autoAddAssociatedItems || false}
              onChange={(checked) => props.onAutoIncludeAssociatedItemsCheck(checked)}
              readOnly={!props.canModify}
            />
            <OverlappingContractsButton contract={props.contract} />
          </Box>
        </Box>
        <DataGrid
          id={'contract-items-grid'}
          rows={[...props.contractTerms.contractItems]}
          getRowId={(r: IContractItem) => r.sku}
          columns={getGridColumns()}
          bodyRowHeight={48}
          headerRowHeight={150}
          showBodyCellRightBorder
          cssOverrides={{
            root: styles.gridOverrides.root,
            bodyRow: styles.gridOverrides.bodyRow,
            header: styles.gridOverrides.header,
            headerCell: styles.gridOverrides.headerCell,
          }}
          processRowUpdate={(row: any) => handleContractItemRowUpdate(row)}
          processMultiRowUpdate={handleContractItemsRowUpdate}
          preferences={{
            columns: {
              visibilityMap: visibilityMap,
            },
          }}
          onColumnVisibilityChange={handleColumnVisibilityChange}
          showInputValidationErrors={showErrors}
          defaultSort={[{ field: 'item.upc', direction: SortDirection.ASC as DataGridSortDirection }]}
          isLoading={props.contractLoading}
        />
      </Box>

      {showUpcSelectionModal && (
        <UpcSelectionModal
          id={'UpcSelection'}
          contractId={props.contract.contractId}
          storeSelections={props.contractTerms.stores}
          open={showUpcSelectionModal}
          onAddToContract={handleUpcAdded}
          onCancel={() => setShowUpcSelectionModal(false)}
        />
      )}
      <DbgDialog
        id="deleteContractItemDialog"
        open={showDeleteContractItemConfirmation}
        cancelText="Cancel"
        sx={dlgStyles.dialogContent}
        confirmButtonTheme={getRedButtonStyleProps()}
        cancelButtonTheme={{ ...getWhiteButtonStyleProps(), width: '150px !important' }}
        confirmText="Yes, Delete"
        onConfirm={handleContractItemModalDeleteConfirm}
        onCancel={() => {
          setShowDeleteContractItemConfirmation(false);
          setContractItemToDelete(undefined);
        }}
        title="Are you sure you want to delete this item?"
      ></DbgDialog>
    </>
  );
};

function CreateInputBodyCell(value: number | undefined) {
  const { styles } = UpcDataGridStyles;

  return (
    <Box sx={styles.promoInputCell}>
      <Box sx={styles.promoInputCell.startAdornment}>$</Box>
      <Box>{value !== undefined && value !== null && NumberToCurrencyString(value)}</Box>
    </Box>
  );
}

function CreateInputBodyIntCell(value: number | undefined) {
  const { styles } = UpcDataGridStyles;

  return (
    <Box sx={styles.promoInputCell}>
      <Box>{value !== undefined && value !== null && value}</Box>
      <Box sx={styles.promoInputCell.endAdornment}>/</Box>
    </Box>
  );
}

function CreateDisabledBodyCell() {
  const { styles } = UpcDataGridStyles;

  return (
    <Tooltip title={'Disabled For random weight'}>
      <Box sx={styles.promoInputCell}>
        <Box sx={styles.promoInputCell.startAdornment}>$</Box>
        <Box>{0}</Box>
      </Box>
    </Tooltip>
  );
}

export default UpcDataGrid;
