export enum DbgButtonSize {
  ExtraLarge = 'ExtraLarge',
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small',
}

export const SortDirection = {
  ASC: 'ASC',
  DESC: 'DESC',
  NONE: 'none',
};

export const TermTypeCategory = {
  Upc: 'UPC',
  LumpSum: 'LUMP SUM',
};

export const UpcSelectionErrorType = {
  SecondaryUpc: 'SECONDARY_UPC',
  UpcDiscontinued: 'UPC_DISCONTINUED',
  UpcNotAuthorized: 'UPC_NOT_AUTHORIZED',
  UpcNotFound: 'UPC_NOT_FOUND',
  OrderCodeNotFound: 'ORDER_CODE_NOT_FOUND',
};

export const ContractValidationErrorTypes = {
  DuplicateContractFound: 'DuplicateContractFound',
};

export enum NotificationType {
  TASK = 'Task',
  PROPOSAL = 'Proposal',
}

export const UpcSelectionSearchType = {
  Upc: 'UPC',
  OrderCode: 'Order Code',
};

export enum ValidationErrorType {
  Error = 'error',
  Warning = 'warning',
}

export enum Permission {
  TasksCreate = 'TASKS_CREATE',
  TermsCreate = 'TERMS_CREATE',
  TermsModify = 'TERMS_MODIFY',
  TermsView = 'TERMS_VIEW',
  PricingView = 'PRICING_VIEW',
  UserManagementView = 'USER_MANAGEMENT',
  ExternalAccountsManage = 'USER_EXTERNAL_ACCOUNTS',
  ExternalAdminsManage = 'USER_EXTERNAL_ADMINS',
  ExternalUsersManage = 'USER_EXTERNAL_USERS',
  AdPlannerExport = 'AD_PLANNER_EXPORT',
}

export enum WorkflowStatus {
  DRAFT = 'DRAFT',
  SUBMITTED = 'SUBMITTED',
  IN_REVIEW = 'IN REVIEW',
  ACCEPTED = 'ACCEPTED',
}

export type ContractPanelAction = WorkflowAction | ProposalAction;

export enum ModifyTermsAction {
  SAVE = 'SAVE',
  DELETE = 'DELETE',
}

export enum WorkflowAction {
  SUBMIT = 'SUBMIT',
  ACCEPT = 'ACCEPT',
}

export enum ProposalAction {
  KEEP_PROPOSAL = 'KEEP_PROPOSAL',
  DROP_PROPOSAL = 'DROP_PROPOSAL',
}

export enum UserType {
  Internal = 'Internal',
  External = 'External',
}

export enum TaskResolutionType {
  COMPLETED = 'COMPLETED',
  DECLINED = 'DECLINED',
}
