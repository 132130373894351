import { FormDialog, TextInput } from '@dierbergs-markets/react-component-library';
import { useStyles } from 'tss-react';
import { IExternalAccount } from '../../../../../models';
import { joiResolver } from '@hookform/resolvers/joi';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Joi from 'joi';
import { useMemo } from 'react';

interface IProps {
  initialAccount: IExternalAccount;
  onAccept: (editedContract: IExternalAccount) => void;
  onCancel: () => void;
  saving: boolean;
}

export default function EditExternalAccountModal(props: IProps) {
  const { css } = useStyles();

  const schema = Joi.object({
    name: Joi.string().required().messages({ 'string.empty': 'Account name is required.' }),
  });

  const resolver = joiResolver(schema, { abortEarly: false, allowUnknown: true }, { mode: 'async' });

  const isNewForm = useMemo(() => props.initialAccount.externalAccountId === 0, [props.initialAccount.externalAccountId]);

  const defaultValues: IExternalAccount = { ...props.initialAccount };

  const onSubmit: SubmitHandler<IExternalAccount> = (data: IExternalAccount) => handleConfirm(data);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<IExternalAccount>({ defaultValues, resolver });

  const handleConfirm = (data: IExternalAccount) => {
    props.onAccept({ ...props.initialAccount, name: data.name });
  };

  return (
    <FormDialog
      title={`${isNewForm ? 'Create' : 'Edit'} Account`}
      classes={{
        content: css({ textAlign: 'center', height: '125px', paddingTop: '3px !important' }),
      }}
      acceptLabel={'Save'}
      cancelLabel={'Cancel'}
      onAccept={handleSubmit(onSubmit)}
      onClose={props.onCancel}
      onCancel={props.onCancel}
      open={true}
      acceptLoading={props.saving}
    >
      <Controller
        control={control}
        name={'name'}
        render={({ field }) => (
          <TextInput id={'accountName'} label={'Account name'} value={field.value} onChange={field.onChange} errorMessage={errors.name?.message} />
        )}
      />
    </FormDialog>
  );
}
