import { tabClasses, dialogContentClasses, paperClasses, typographyClasses } from '@mui/material';
import { defaultColors } from '../../../../styles/variables';

const styles = {
  root: {},
  contractPageHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  centeredMessage: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: 'calc(100vh - 300px)',
    marginLeft: '10px',
    fontSize: '16px',
    fontWeight: 500,
    spinner: {
      margin: '10px',
      color: defaultColors.blue,
    },
  },
  mainContent: {},
  navBar: {
    backgroundColor: defaultColors.white,
    width: '100%',
    height: '55px',
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: '8px',
  },
  backContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  actionBtnContainer: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  addButton: {
    width: '136px',
    borderRadius: '6px',
    height: '30px',
    padding: '6px 0',
  },
  addVendorFundedProgramsButton: {
    width: '200px',
    borderRadius: '6px',
    height: '30px',
    padding: '6px 0',
  },
  addUpcBtn: {
    color: defaultColors.blue,
    width: '149px',
    fontWeight: 500,
    height: '38px',
  },
  saveContractBtn: {
    width: '90px',
    fontWeight: 500,
    marginLeft: '10px',
    marginBottom: 'auto',
    marginTop: 'auto',
    borderRadius: '6px',
    height: '30px',
    savingIcon: {
      display: 'flex',
      height: '18px !important',
      width: '18px !important',
      marginRight: '5px',
      color: defaultColors.lightGrey,
    },
  },
  getStarted: {
    marginTop: '270px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  getStartedLabel: {
    marginBottom: '24px',
    fontSize: '25px',
    fontWeight: 500,
  },
  tabsRoot: {
    [`& .${tabClasses.root}`]: {
      fontSize: '15px',
      lineHeight: '19px',
      textTransform: 'capitalize',
    },
    [`& .${tabClasses.selected}`]: {
      color: `${defaultColors.blue} !important`,
    },
  },
  tabIndicator: {
    backgroundColor: defaultColors.blue,
    height: '2px',
  },
  unsavedChangesModal: {
    [`& .${paperClasses.root}`]: {
      height: '428px',
      width: '621px',
      maxWidth: '621px',
      maxHeight: '621px',
    },
    [`& .${dialogContentClasses.root}`]: {
      display: 'block',
    },
  },
  beforeProposeChangesModal: {
    [`& .${typographyClasses.h6}`]: {
      marginTop: '20px',
    },
    [`& .${paperClasses.root}`]: {
      height: '200px',
      width: '421px',
      maxWidth: '621px',
      maxHeight: '421px',
    },
    [`& .${dialogContentClasses.root}`]: {
      display: 'block',
    },
  },
  proposedChangesModal: {
    [`& .${typographyClasses.h6}`]: {
      marginTop: '20px',
    },
    [`& .${paperClasses.root}`]: {
      height: '300px',
      width: '460px',
      maxWidth: '621px',
      maxHeight: '500px',
    },
    [`& .${dialogContentClasses.root}`]: {
      display: 'block',
    },
  },
  lastSavedText: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    verticalAlign: 'middle',
    flexDirection: 'column',
    marginLeft: '13px',
    fontSize: '12px',
    color: defaultColors.mediumGrey,
  },
  headerWrapper: {
    position: 'absolute',
    top: '0',
    left: '40px',
    display: 'flex',
    height: '46px',
    width: 'calc(100% - 130px)',
  },
  headerWrapperAdmin: {
    width: 'calc(100vw - 135px)',
  },
  headerRight: {
    alignSelf: 'center',
    marginLeft: 'auto',
  },
  headerBtn: {
    color: defaultColors.darkGrey,
    backgroundColor: defaultColors.transparent,
    fontWeight: 500,
    fontSize: '24px',
    marginBottom: 'auto',
    marginTop: 'auto',
    width: 'initial',
    padding: 0,
    '&:hover': {
      backgroundColor: defaultColors.lightGrey,
    },
  },
  headerBtnIcon: {
    fontSize: '22px',
    width: '22px',
  },
  unsavedChangesModalContent: {
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '27px',
    width: '389px',
    margin: '0 auto 39px auto',
  },
  unsavedChangesModalButtons: {
    borderRadius: '8px',
    fontSize: '14px',
    width: '290px',
    height: '50px',
    marginBottom: '15px',
  },
  duplicateContractModal: {
    padding: '16px',
    message: {
      marginBottom: '10px',
    },
    linkWrapper: {
      borderRadius: '4px',
      '&:hover': {
        backgroundColor: defaultColors.altGrey,
      },
      'a,a:hover,a:active': {
        color: defaultColors.darkGrey,
        padding: '9px 8px 9px 14px',
        lineHeight: '28px',
        fontWeight: 500,
        fontSize: '15px',
        height: '46px',
        display: 'block',
        textDecoration: 'none',
      },
    },
  },
  timeAgo: {
    marginLeft: '15px',
    fontSize: '12px',
    color: defaultColors.altMediumGrey,
  },
  version: {
    marginLeft: '15px',
    paddingTop: '2px',
    color: defaultColors.white,
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0',
    lineHeight: '15px',
    height: '18px',
    width: '122px',
    borderRadius: '3px',
    current: {
      backgroundColor: defaultColors.green,
    },
    proposed: {
      backgroundColor: defaultColors.red,
    },
  },

  footerWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '20px',
    alignItems: 'center',
  },
  footerStatus: {
    paddingRight: '20px',
  },
  footerButtonWidth: {
    width: '150px',
  },
};

export const ContractPageStyles = {
  styles,
};
