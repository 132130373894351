import * as React from 'react';
import { Box, IconButton } from '@mui/material';
import { defaultColors } from '../../../../styles/variables';
import { v4 as uuid } from 'uuid';
import { format } from 'date-fns';
import { FiArrowRight } from 'react-icons/fi';
import { memo, useCallback, useEffect, useRef, useState } from 'react';
import { contractSearchService } from '../../../../services';
import { useSnackbar } from 'notistack';
import { HttpErrorResponse } from '../../../../services/contractHubApi';
import { RouteEnum } from '../../../layout/PageRouter';
import { useNavigate } from 'react-router-dom';
import { IContractsSearchRequest } from '../../../../models/requests';
import { IContractsSearchResponse } from '../../../../models/responses';
import { SortDirection } from '../../../../models/enums';
import { IAdvSearch, ISupplier } from '../../../../models';
import ContractActionMenuButton from './ContractActionMenuButton';
import { ContractsGridLocalState, ContractsGridSessionState, useContractsGridLocalStore, useContractsGridSessionStore } from '../../../../store';
import {
  DataGrid,
  DataGridColDef,
  DataGridColumnVisibilityMap,
  DataGridFilterField,
  DataGridRowModel,
  DataGridSortField,
  DateRangePickerInput,
  ISortConfig,
  DataGridSortDirection,
  ColumnOrderMap,
} from '@dierbergs-markets/react-component-library';
import dayjs, { Dayjs } from 'dayjs';
import { DateRange } from '@mui/x-date-pickers-pro';
import SupplierToolTip from '../../../components/shared/SupplierToolTip';
import AdvancedSearchCustomFilterFormComponent from './AdvancedSearchCustomFilterFormComponent';

export interface IPageSearchFilters {
  startDate: Date | null;
  endDate: Date | null;

  pageNumber: number;
  pageSize: number;
  sortConfig?: ISortConfig[];
}

const ContractsDataGrid = memo(() => {
  const LOCALSTORAGE_COLUMN_VISIBILITY = 'contractsDataGrid.preferences.colVisibility';
  const LOCALSTORAGE_COLUMN_ORDER = 'contractsDataGrid.preferences.colOrder';

  const { advSearchInputs, setAdvSearchInputs, dataGridFilters, setDataGridFilters }: ContractsGridLocalState = useContractsGridLocalStore();
  const { timeFrameState, setTimeFrameState }: ContractsGridSessionState = useContractsGridSessionStore();
  const [daterange, setDateRange] = useState<DateRange<Dayjs>>([null, null]);

  const defaultPageSize = 150;
  const defaultSortColumn = 'contractId';
  const defaultSortDirection = SortDirection.DESC;
  const defaultSortConfig = [{ sortColumns: defaultSortColumn, sortDirections: defaultSortDirection }];
  const gridDefaultSort: DataGridSortField[] = [{ field: defaultSortColumn, direction: defaultSortDirection as DataGridSortDirection }];

  const [searchQuery, setSearchQuery] = useState<IPageSearchFilters>({
    pageNumber: 0,
    pageSize: defaultPageSize,
    startDate: timeFrameState.startDate ?? dayjs().add(-2, 'months').toDate(),
    endDate: timeFrameState.startDate ? timeFrameState.endDate ?? null : null,
    sortConfig: defaultSortConfig,
  });

  const [loading, setIsLoading] = useState<boolean>(false);
  const [dataOnScroll, setDataOnScroll] = useState<IContractsSearchResponse[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [dateRangeError, setDateRangeError] = useState<string | null>(null);
  // useRef instead of useState. The latter has a little delay.
  const controllerRef = useRef<AbortController | null>(null);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const getFilterValue = (name: string): string | undefined => {
    const filter = dataGridFilters?.find((q) => q.field === name);
    if (filter) return filter.filter;

    return undefined;
  };
  const isSearchingForSpecificContract = !!getFilterValue('contractId') || !!getFilterValue('vendorContractNumber');

  //enforce valid date, first time loading.
  useEffect(() => {
    setTimeFrameState({ startDate: searchQuery.startDate, endDate: searchQuery.endDate });
    setDateRange([dayjs(searchQuery.startDate), searchQuery.endDate ? dayjs(searchQuery.endDate) : null]);
  }, []);

  const timeFrameDrivenSearch = useCallback(() => {
    setSearchQuery({
      ...searchQuery,
      pageNumber: 0,
      startDate: timeFrameState.startDate,
      endDate: timeFrameState.endDate,
    });

    setDataOnScroll([]);
  }, [timeFrameState]);

  const validateDateRange = useCallback((dateRange: DateRange<Dayjs>) => {
    const [startDate, endDate] = dateRange;

    if (!startDate) {
      setDateRangeError('Start date is required');
      return false;
    }

    if (startDate && endDate && startDate.isValid() && endDate.isValid() && endDate.toDate() < startDate.toDate()) {
      setDateRangeError('Start date must be no later than end date');
      return false;
    }

    if (startDate.isValid() && (!endDate || endDate.isValid())) {
      setDateRangeError(null);
      return true;
    }
    setDateRangeError('Invalid input');
    return false;
  }, []);

  const getDistinct = (array: IContractsSearchResponse[]) => {
    return array.filter((q, i) => array.findIndex((a) => a.contractId === q.contractId) === i);
  };

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const q = searchQueryWithGridFilters();
      const previousControllerRef = controllerRef.current;
      if (previousControllerRef && previousControllerRef.signal.aborted === false) {
        previousControllerRef.abort();
      }
      controllerRef.current = new AbortController();
      const response = await contractSearchService.search(q, controllerRef.current.signal);
      controllerRef.current = null;
      if (response instanceof HttpErrorResponse) {
        if (!response.isCanceled) {
          enqueueSnackbar('Unable to retrieve contracts.', { variant: 'error' });
        }
      } else {
        setIsLoading(false);
        if (q.pageNumber === 0) setDataOnScroll(response.results);
        else setDataOnScroll(getDistinct([...dataOnScroll, ...response.results]));
        setTotalCount(response.totalResults);
      }
    })();
  }, [searchQuery]);

  const getGridColumns = (): DataGridColDef[] => {
    return [
      {
        field: 'categoryManagerName',
        headerName: 'Category Manager',
        type: 'string',
        width: 200,
        filterable: true,
        sortable: true,
      },
      {
        field: 'customerId',
        headerName: 'Bill to Account #',
        type: 'string',
        width: 150,
        filterable: true,
        sortable: true,
      },
      {
        field: 'customerName',
        headerName: 'Bill to Acct Name',
        width: 150,
        type: 'string',
        filterable: true,
        sortable: true,
      },
      {
        field: 'suppliers',
        headerName: 'Supplier(s) #',
        width: 100,
        type: 'string',
        sortable: false,
        filterable: true,
        renderBodyCellContent: (x) => {
          const supplierArr: ISupplier[] = x.value;
          if (supplierArr && Array.isArray(supplierArr) && supplierArr.length > 1) {
            return <SupplierToolTip supplierArr={supplierArr} />;
          } else if (supplierArr && supplierArr.length === 1) {
            return supplierArr[0].id;
          } else {
            return <></>;
          }
        },
      },
      {
        field: 'suppliersName',
        headerName: 'Supplier Name',
        width: 200,
        type: 'string',
        sortable: false,
        filterable: true,
        valueGetter: (params) => {
          const supplierArr = params.row.suppliers;
          if (supplierArr && supplierArr.length === 1) {
            return params.row.suppliers[0].name;
          } else {
            return '';
          }
        },
        renderBodyCellContent: (x) => (x.value === '' ? <></> : x.value),
      },
      {
        field: 'vendorContractNumber',
        headerName: 'Contract #',
        width: 270,
        type: 'string',
        filterable: true,
        sortable: true,
        hideable: false,
      },
      {
        field: 'manufacturer',
        headerName: 'Manufacturer',
        width: 200,
        type: 'string',
        filterable: true,
        sortable: true,
      },
      {
        field: 'startDate',
        headerName: 'Min. Start Date',
        width: 150,
        filterable: false,
        type: 'string',
        sortable: true,
        renderBodyCellContent: (x) => format(new Date(x.value), 'MM/dd/yy'),
      },
      {
        field: 'productLine',
        headerName: 'Product Line',
        flex: 0.5,
        type: 'string',
        filterable: true,
        sortable: true,
        minWidth: 110,
      },
      {
        field: 'comments',
        headerName: 'Comments',
        type: 'string',
        filterable: true,
        sortable: true,
        width: 200,
        renderBodyCellContent: (x) => {
          return (
            <Box sx={styles.gridComments} title={x.value}>
              {x.value}
            </Box>
          );
        },
      },
      {
        field: 'contractId',
        headerName: 'Internal Contract #',
        width: 175,
        type: 'number',
        filterable: true,
        sortable: true,
        hideable: false,
      },
      {
        field: 'stateText',
        headerName: 'Status',
        width: 150,
        type: 'string',
        filterable: true,
        sortable: true,
      },
      {
        field: 'Actions',
        headerName: '',
        type: 'string',
        filterable: false,
        sortable: false,
        width: 65,
        fixedAlignPosition: 'right',
        hideable: false,
        renderBodyCellContent: (x) => <ContractActionMenuButton contract={x.row} />,
      },
    ];
  };

  const defaultVisiblityMap: DataGridColumnVisibilityMap = getGridColumns().reduce((acc, cur, index) => {
    const fieldName = cur.field || `column_${index}`;

    if (cur.hideable === false) return acc;

    return { ...acc, [fieldName]: true };
  }, {});

  const rawVisibilityMap = localStorage.getItem(LOCALSTORAGE_COLUMN_VISIBILITY);
  const rawColumnOrder = localStorage.getItem(LOCALSTORAGE_COLUMN_ORDER);
  const [visibilityMap, setVisibilityMap] = useState<DataGridColumnVisibilityMap>(
    rawVisibilityMap ? JSON.parse(rawVisibilityMap) : defaultVisiblityMap
  );
  const [columnOrder, setColumnOrder] = useState<ColumnOrderMap>(rawColumnOrder ? JSON.parse(rawColumnOrder) : {});

  function handleColumnVisibilityChange(map: DataGridColumnVisibilityMap) {
    setVisibilityMap(map);
    localStorage.setItem(LOCALSTORAGE_COLUMN_VISIBILITY, JSON.stringify(map));
  }
  function handleColumnOrderChange(order: ColumnOrderMap) {
    setColumnOrder(order);
    localStorage.setItem(LOCALSTORAGE_COLUMN_ORDER, JSON.stringify(order));
  }
  function searchQueryWithGridFilters(): IContractsSearchRequest {
    const newSearchQuery = { ...searchQuery, pageSize: defaultPageSize } as IContractsSearchRequest;
    newSearchQuery.categoryManagerNameFragment = getFilterValue('categoryManagerName');
    newSearchQuery.customerIdFragment = getFilterValue('customerId');
    newSearchQuery.customerNameFragment = getFilterValue('customerName');
    newSearchQuery.supplierIdFragment = getFilterValue('suppliers');
    newSearchQuery.supplierNameFragment = getFilterValue('suppliersName');
    newSearchQuery.vendorContractNumberFragment = getFilterValue('vendorContractNumber');
    newSearchQuery.productLineFragment = getFilterValue('productLine');
    newSearchQuery.commentsFragment = getFilterValue('comments');
    newSearchQuery.contractIdFragment = getFilterValue('contractId');
    newSearchQuery.stateText = getFilterValue('stateText');
    newSearchQuery.manufacturer = getFilterValue('manufacturer');
    newSearchQuery.upc = null;
    newSearchQuery.orderCode = '';

    if (advSearchInputs.upc) {
      newSearchQuery.upc = isNaN(parseInt(advSearchInputs.upc)) ? null : parseInt(advSearchInputs.upc);
    } else if (advSearchInputs.orderCode) {
      newSearchQuery.orderCode = advSearchInputs.orderCode;
    }
    newSearchQuery.brandFragment = advSearchInputs?.brand?.name ?? '';
    newSearchQuery.descriptionFragment = advSearchInputs?.description ?? '';

    if (newSearchQuery.sortConfig?.length === 0) {
      newSearchQuery.sortConfig = defaultSortConfig;
    }

    if (isSearchingForSpecificContract) {
      newSearchQuery.startDate = null;
      newSearchQuery.endDate = null;
    }

    return newSearchQuery;
  }

  const handleFilterChange = (filters: DataGridFilterField[], customModel: IAdvSearch) => {
    setDataOnScroll([]); //reset grid data before triggering server call
    setDataGridFilters(filters);
    setAdvSearchInputs(customModel ?? { upc: '', brand: null, description: '', orderCode: '', isSearchExecuted: true });
    setSearchQuery({ ...searchQuery, pageNumber: 0 });
  };

  const handleSortingColumnChange = useCallback(
    (fields: DataGridSortField[]) => {
      setDataOnScroll([]);

      const sortConfig: ISortConfig[] = [];

      for (const field of fields) {
        sortConfig.push({ sortColumns: field.field, sortDirections: field.direction });
      }

      setSearchQuery({ ...searchQuery, sortConfig: sortConfig, pageNumber: 0 });
    },
    [searchQuery]
  );

  const handleOnRowsScrollEnd = () => {
    if (dataOnScroll.length < totalCount) {
      setSearchQuery({ ...searchQuery, pageNumber: searchQuery.pageNumber + 1 });
    }
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.toolBar}>
        <Box sx={styles.toolBarPickerContainer}>
          <DateRangePickerInput
            id={'ContractDateRange'}
            slotProps={{ textField: { sx: { height: '60px', borderTopRightRadius: '0px !important', borderBottomRightRadius: '0px !important' } } }}
            label="Select date range"
            format={'MM/DD/YY'}
            value={daterange}
            disabled={isSearchingForSpecificContract}
            onChange={(value: DateRange<Dayjs>) => {
              setDateRange(value);
              if (validateDateRange(value) && value[0]) {
                setTimeFrameState({ startDate: value[0].toDate(), endDate: value[1]?.toDate() ?? null });
              }
            }}
            errorMessage={dateRangeError ?? ''}
          />
          <Box sx={styles.toolBarLeftArrowContainer}>
            <IconButton
              onClick={() => {
                if (validateDateRange(daterange)) {
                  timeFrameDrivenSearch();
                }
              }}
            >
              <FiArrowRight />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <DataGrid
        id={'contract-dashboard-data-grid'}
        columnOrderable={true}
        rows={dataOnScroll}
        getRowId={(r: IContractsSearchResponse) => r?.contractId ?? uuid()}
        columns={getGridColumns()}
        bodyRowHeight={52}
        headerRowHeight={56}
        isLoading={loading}
        cssOverrides={{
          root: styles.gridOverrides.root,
          header: styles.gridOverrides.header,
        }}
        showInputValidationErrors={true}
        preferences={{
          filters: dataGridFilters,
          customFilters: advSearchInputs,
          columns: {
            visibilityMap: visibilityMap,
          },
          columnOrder: columnOrder,
        }}
        onColumnVisibilityChange={handleColumnVisibilityChange}
        onColumnOrderChange={handleColumnOrderChange}
        onFilterChange={handleFilterChange}
        onRowsScrollEnd={handleOnRowsScrollEnd}
        onColumnSortChange={handleSortingColumnChange}
        onRowClick={(row: DataGridRowModel) => {
          navigate(`${RouteEnum.Contract}/${row.contractId}`);
        }}
        CustomFilterFormComponent={AdvancedSearchCustomFilterFormComponent}
        defaultSort={gridDefaultSort}
      />
    </Box>
  );
});

export default ContractsDataGrid;

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 150px)',
    marginTop: '-40px',
  },
  toolBar: {
    display: 'flex',
    alignItems: 'center',
    zIndex: 10,
    width: `calc(100% - 400px)`,
    paddingBottom: '14px',
    marginLeft: '10px',
    marginTop: '38px',
    height: '74px',
  },
  toolBarPickerContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  toolBarLeftArrowContainer: {
    padding: '10px 0px 10px 0px',
    border: `1px solid ${defaultColors.grey}`,
    borderLeft: 'unset',
    borderRadius: '0px 6px 6px 0px',
    height: '60px',
  },
  gridComments: {
    width: '100%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  gridOverrides: {
    root: {
      border: '0',
      display: 'flex',
      flexDirection: 'column' as unknown as 'column',
      overflow: 'hidden',
      marginTop: '6px',
    },
    header: {
      backgroundColor: defaultColors.lightGrey,
      padding: '10px 0 18px 6px',
      maxHeight: '56px',
      minHeight: '56px',
    },
  },
};
