import React from 'react';
import { Tooltip } from '@mui/material';

const SupplierToolTip = ({ supplierArr }) => {
  return (
    <Tooltip
      title={
        <>
          <table>
            <tbody>
              <tr>
                <th style={{ textAlign: 'left' }}>Supplier #(s)</th>
                <th style={{ textAlign: 'left', paddingLeft: '25px' }}>Supplier Name</th>
              </tr>
              {supplierArr.map((supplier, index) => (
                <tr key={index}>
                  <td>{`${supplier.id.trim()}`}</td>
                  <td style={{ paddingLeft: '25px' }}>{`${supplier.name.trim()}`}</td>
                </tr>
              ))}
            </tbody>
          </table>{' '}
        </>
      }
    >
      <a href="">{supplierArr.length}</a>
    </Tooltip>
  );
};

export default SupplierToolTip;
