import { alpha } from '@mui/material';

export const defaultColors = {
  red: '#DA5844',
  blue: '#009BFF',
  green: '#67AE44',
  yellow: '#FFC278',
  white: '#FFFFFF',
  lightGrey: '#EFF6FF',
  altGrey: '#F5F7FA',
  darkGrey: '#27262D',
  mediumGrey: '#616B7C',
  altMediumGrey: '#909FC2',
  grey: '#D8DCDF',
  border: '#D8DCDF',
  borderAlt: '#CCD4E5',
  black: '#000000',
  transparent: alpha('#FFFFFF', 0.0),
  background: '#EFF6FF',
  blueGrey: '#BBC4D7',
  gridRowGrey: '#C5CEE1',
  darkBlueGrey: '#8793AC',
  transparentMediumGrey: alpha('#616B7C', 0.1),
  transparentBlue: alpha('#019BFF', 0.23),
  lightBlue: '#3494E8',
  lightBlueGrey: '#E7F5FF',
  mediumBlueGrey: '#8696BB',
  transparentMediumBlueGrey: alpha('#8696BB', 0.38),
  numberInputAdornment: '#C3CBDE',
  rowHover: '#ECEFF5',
  tables: {
    rowHover: '#ECEFF5',
  },
};

const text = {
  primary: defaultColors.darkGrey,
  secondary: defaultColors.mediumGrey,
  contrast: defaultColors.white,
  error: defaultColors.red,
  success: defaultColors.green,
  info: defaultColors.blue,
};

const buttons = {
  blue: defaultColors.blue,
  red: defaultColors.red,
  green: defaultColors.green,
  yellow: defaultColors.yellow,
  dark: defaultColors.darkGrey,
  light: defaultColors.grey,
  white: defaultColors.white,
};

const shapes = {
  border: defaultColors.border,
};

export const defaultBorders = {
  boxShadow: `0 2px 4px 0 ${alpha(defaultColors.mediumGrey, 0.1)}`,
  hover: `0 3px 8px 0 ${defaultColors.transparentBlue}`,
};

export default { buttons, text, shapes, defaultColors, defaultBorders };
