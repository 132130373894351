import { memo, useEffect, useState } from 'react';
import PricingModal, { IPricingModalState } from './PricingModal';
import { getRedButtonStyleProps, getWhiteButtonStyleProps } from '../../../../../styles/themes';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { IContract, IPricing } from '../../../../../models';
import dayjs from 'dayjs';
import PricingGridContainer from './PricingGridContainer';
import DbgDialog from '../../../../components/dialogs/DbgDialog';
import { DialogSharedStyles } from '../../styles/DialogSharedStyles';
import { v4 } from 'uuid';
import * as PG from '@dierbergs-markets/react-feature-library';
import { Updater } from 'use-immer';
import { ContractPageEvents } from '../../ContractPage';
import { eventEmitterService } from '../../../../../services';

interface IProps {
  contract: IContract;
  setContract: Updater<IContract | undefined>;
  canModify: boolean;
}
const PricingSection = memo((props: IProps) => {
  //STATE
  const [pricingModalState, setPricingModalState] = useState<Partial<IPricingModalState> | undefined>(undefined);
  const [showDeletePricingConfirmation, setShowDeletePricingConfirmation] = useState<boolean>(false);
  const [pricingModalOpen, setPricingModalOpen] = useState(false);

  const { contract, setContract } = props;

  // CSS
  const { styles: dlgStyles } = DialogSharedStyles;

  //MISC. HOOKS
  const { referenceData } = useApplicationContextState();

  // USE MEMOS

  //USE EFFECTS

  useEffect(() => {
    eventEmitterService.on(ContractPageEvents.ADD_NEW_PRICING, handleAddInitialPricingClick);
    return () => {
      eventEmitterService.off(ContractPageEvents.ADD_NEW_PRICING);
    };
  }, []);

  //FUNCTIONS - PRICING GRID

  function handlePricingGridRowUpdate(row: PG.IPricingItem) {
    setContract((draftContract) => {
      updatePricingItems(draftContract, [row]);
    });
  }

  function handlePricingGridMultiRowUpdate(rows: PG.IPricingItem[]) {
    setContract((draftContract) => {
      updatePricingItems(draftContract, rows);
    });
  }

  function updatePricingItems(draftContract: IContract | undefined, rows: PG.IPricingItem[]) {
    if (!draftContract) return;

    rows.forEach((row) => {
      if (!row.prices) return;

      row.prices.forEach((price, index) => {
        if (!draftContract.pricings) return;

        const priceItemIndex = draftContract.pricings[index].items.findIndex((i) => i.sku === row.item.sku);
        if (priceItemIndex === -1) {
          draftContract.pricings[index].items.push({
            sku: row.item.sku,
            price: price?.price ?? 0,
            multiple: price?.multiple ?? 0,
          });
        } else {
          draftContract.pricings[index].items[priceItemIndex].price = price?.price ?? 0;
          draftContract.pricings[index].items[priceItemIndex].multiple = price?.multiple ?? 0;
        }
      });
    });
  }

  //FUNCTIONS - PRICING MODAL

  function onPricingModalCancel() {
    setPricingModalOpen(false);
    setPricingModalState(undefined);
  }

  function onPricingModalSave(savedPricingState: IPricingModalState) {
    if (!referenceData || !savedPricingState.priceTypeId || !contract) return;

    if (!savedPricingState.pricingId && !savedPricingState.uniqueId) throw new Error('pricingId or unqiueId must be set.');

    setContract((draftContract) => {
      if (!draftContract || !savedPricingState.dateRange[0] || !savedPricingState.dateRange[1]) return;

      if (!draftContract.pricings) draftContract.pricings = [];

      const pricingIndex = draftContract.pricings.findIndex((p) =>
        savedPricingState.pricingId ? p.pricingId === savedPricingState.pricingId : p.uniqueId === savedPricingState.uniqueId
      );

      if (pricingIndex === -1) {
        draftContract.pricings.push({
          uniqueId: savedPricingState.uniqueId ?? v4(),
          priceTypeId: savedPricingState.priceTypeId,
          startDate: savedPricingState.dateRange[0].toDate(),
          endDate: savedPricingState.dateRange[1].toDate(),
          comments: savedPricingState.comments,
          itemTerms: savedPricingState.itemTerms,
          items: contract.terms.contractItems.map((ci) => ({ sku: ci.sku, multiple: 1, price: 0 })),
        });
      } else {
        draftContract.pricings[pricingIndex].uniqueId = savedPricingState.uniqueId ?? v4();
        draftContract.pricings[pricingIndex].startDate = savedPricingState.dateRange[0].toDate();
        draftContract.pricings[pricingIndex].endDate = savedPricingState.dateRange[1].toDate();
        draftContract.pricings[pricingIndex].endDate = savedPricingState.dateRange[1].toDate();
        draftContract.pricings[pricingIndex].priceTypeId = savedPricingState.priceTypeId;
        draftContract.pricings[pricingIndex].comments = savedPricingState.comments;
        draftContract.pricings[pricingIndex].itemTerms = savedPricingState.itemTerms;
      }
    });

    setPricingModalOpen(false);
    setPricingModalState(undefined);
  }

  function handlePricingDelete(pricingId?: number, uniqueId?: string) {
    if (!pricingId && !uniqueId) throw new Error('Unable to delete Vendor Funded Program.');

    setContract((draftContract) => {
      if (!draftContract || !draftContract.pricings) return;

      const pricingIndex = draftContract.pricings.findIndex((p) =>
        pricingId && pricingId > 0 ? p.pricingId === pricingId : uniqueId && p.uniqueId === uniqueId
      );

      if (pricingIndex !== -1) {
        draftContract.pricings.splice(pricingIndex, 1);
        draftContract.pricings.forEach((price) => {
          price.items.splice(pricingIndex, 1);
        });
      }
    });
  }

  function onPricingModalDelete() {
    setShowDeletePricingConfirmation(false);
    pricingModalState && handlePricingDelete(pricingModalState.pricingId, pricingModalState.uniqueId);
    setPricingModalState(undefined);
  }

  function handleAddInitialPricingClick() {
    if (!contract) return;

    setPricingModalState({ uniqueId: v4(), itemTerms: [] });
    setPricingModalOpen(true);
  }

  function handlePricingGridPricingEdit(pricing: IPricing) {
    setPricingModalState({
      pricingId: pricing.pricingId,
      comments: pricing.comments,
      dateRange: [dayjs(pricing.startDate), dayjs(pricing.endDate)],
      itemTerms: pricing.itemTerms.filter((it) => {
        //if same contract, and promotion doesn't exist (deleted during current session), don't include the term
        if (contract && contract.contractId === it.contract.contractId) {
          const termExists = contract.terms.contractTermsForItem.some((cti) => cti.uniqueId === it.itemTermUniqueId);
          return termExists;
        }
        return true;
      }),
      priceTypeId: pricing.priceTypeId,
      uniqueId: pricing.uniqueId,
    });
    setPricingModalOpen(true);
  }
  return (
    <>
      <PricingGridContainer
        contract={contract}
        canModify={props.canModify}
        contractLoading={false}
        onRowUpdate={handlePricingGridRowUpdate}
        onMultiRowUpdate={handlePricingGridMultiRowUpdate}
        onPricingEdit={handlePricingGridPricingEdit}
      />
      {pricingModalOpen && pricingModalState && (
        <PricingModal
          id={'PricingModal'}
          contract={contract}
          initialState={pricingModalState}
          open={pricingModalOpen}
          onDelete={() => props.canModify && setShowDeletePricingConfirmation(true)}
          onSave={onPricingModalSave}
          onCancel={onPricingModalCancel}
        />
      )}
      <DbgDialog
        id="deletePricingConfirmationDialog"
        open={props.canModify && showDeletePricingConfirmation}
        cancelText="Cancel"
        sx={dlgStyles.dialogContent}
        confirmButtonTheme={getRedButtonStyleProps()}
        cancelButtonTheme={{ ...getWhiteButtonStyleProps(), width: '150px !important' }}
        confirmText="Yes, Delete"
        onConfirm={onPricingModalDelete}
        onCancel={() => setShowDeletePricingConfirmation(false)}
        title="Are you sure you want to delete this pricing?"
      ></DbgDialog>
    </>
  );
});

export default PricingSection;
