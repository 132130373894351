import { ValidationError } from '../classes/ValidationError';
import { IReferenceDataState } from '../contexts/ApplicationContext';
import { ICategoryManager, IContractItem, IContractTermForItem, IContractTermForLumpSum, IContractTerms, IDepartment, ITermType } from '../models';
import { NumberToCurrencyString } from '../utilities/NumberUtility';
import { HttpErrorResponse } from './contractHubApi';
import { customerService } from './customerService';
import { manufacturerService } from './manufacturerService';

export const MAX_AMOUNT = 999999.99;

const validateVendorContractNumber = (vendorContractNumber?: string): ValidationError | undefined => {
  const fieldName = 'VendorContractNumber';

  if (!vendorContractNumber) return ValidationError.Required({ field: fieldName });
};

const validateCustomer = async (customerId?: string): Promise<ValidationError | undefined> => {
  const fieldName = 'CustomerId';
  const displayField = 'Account #';

  if (!customerId) return ValidationError.Required({ field: fieldName, displayField: displayField });

  const customer = await customerService.selectById(customerId);

  if (customer instanceof HttpErrorResponse || !customer?.isActive) return ValidationError.NotFound({ field: fieldName, displayField: displayField });
};

const validateManufacturer = async (manufacturerId?: string): Promise<ValidationError | undefined> => {
  const fieldName = 'ManufacturerId';
  const displayField = 'Manufacturer';

  if (!manufacturerId) return ValidationError.Required({ field: fieldName, displayField: displayField });

  const manufacturer = await manufacturerService.selectManufacturerById(manufacturerId);

  if (manufacturer instanceof HttpErrorResponse || !manufacturer?.isActive)
    return ValidationError.NotFound({ field: fieldName, displayField: displayField });
};

const validateCategoryManager = async (categoryManagers: ICategoryManager[], categoryManagerId?: string): Promise<ValidationError | undefined> => {
  const fieldName = 'CategoryManagerId';
  const displayField = 'Category Manager';

  if (!categoryManagerId) return ValidationError.Required({ field: fieldName, displayField: displayField });

  const categoryManager = categoryManagers.find((q) => q.id === categoryManagerId && q.isActive);
  if (!categoryManager) return ValidationError.NotFound({ field: fieldName, displayField: displayField });
};

const validateContractTermsForLumpSum = (items: IContractTermForLumpSum[], departments: IDepartment[], termTypes: ITermType[]): ValidationError[] => {
  const fieldName = 'ContractTermsForLumpSum';

  const errors: ValidationError[] = [];

  for (const item of items) {
    if (!item.departmentId)
      errors.push(
        new ValidationError({
          message: 'Department ID is required for Vendor Funded Programs.',
          field: 'DepartmentId',
          identifier: item.uniqueId,
        }).ScopeField(fieldName)
      );
    else {
      const department = departments.find((q) => q.id === item.departmentId);
      if (!department?.isActive)
        errors.push(ValidationError.NotFound({ field: 'DepartmentId', identifier: item.uniqueId, displayField: 'Department' }).ScopeField(fieldName));
    }

    if (!item.effectiveDate)
      errors.push(
        new ValidationError({
          message: 'Effective Date is required for Vendor Funded Programs.',
          field: 'EffectiveDate',
          identifier: item.uniqueId,
        }).ScopeField(fieldName)
      );

    const termType = termTypes.find((tt) => tt.termTypeId === item.termTypeId);

    if (!termType)
      errors.push(
        new ValidationError({
          message: 'Type is required for Vendor Funded Programs.',
          field: 'TermTypeId',
          identifier: item.uniqueId,
        }).ScopeField(fieldName)
      );
    else {
      if (!termType.isAd && item.amount <= 0)
        errors.push(
          ValidationError.GreaterThan({ input: 'Amount', comparison: '0.00', field: 'Amount', identifier: item.uniqueId }).ScopeField(fieldName)
        );
      if (item.amount > MAX_AMOUNT)
        errors.push(
          ValidationError.LessThanOrEqual({
            input: 'Amount',
            comparison: NumberToCurrencyString(MAX_AMOUNT),
            field: 'Amount',
            identifier: item.uniqueId,
          }).ScopeField(fieldName)
        );
    }
  }

  return errors;
};

const validateContractTermsForItem = (promotions: IContractTermForItem[]): ValidationError[] => {
  const fieldName = 'ContractTermsForItem';

  const errors: ValidationError[] = [];

  for (const promotion of promotions) {
    if (!promotion.startDate)
      errors.push(ValidationError.Required({ field: 'StartDate', identifier: promotion.uniqueId, displayField: 'Start Date' }).ScopeField(fieldName));
    if (!promotion.endDate)
      errors.push(ValidationError.Required({ field: 'EndDate', identifier: promotion.uniqueId, displayField: 'End Date' }).ScopeField(fieldName));
    if (!promotion.termTypeId)
      errors.push(
        ValidationError.Required({ field: 'TermTypeId', identifier: promotion.uniqueId, displayField: 'Promotion Type' }).ScopeField(fieldName)
      );
    if (promotion.startDate && promotion.endDate && promotion.startDate > promotion.endDate) {
      errors.push(
        ValidationError.GreaterThan({ input: 'EndDate', comparison: 'StartDate', field: 'StartDate', identifier: promotion.uniqueId }).ScopeField(
          fieldName
        )
      );
    }
  }

  return errors;
};

const validateContractItemAmounts = (
  item: IContractItem,
  contractTermsForItems: IContractTermForItem[],
  fieldName: string,
  referenceData?: IReferenceDataState
): ValidationError[] => {
  const errors: ValidationError[] = [];
  //Since there can be multiple billback promos we must look through all the amounts.
  for (let i = 0; i < item.amounts.length; i++) {
    const error_msg = ValidationError.Required({
      field: `promo_${contractTermsForItems[i].termTypeId ?? 0}_${i}`,
      identifier: item.uniqueId,
      displayField: 'Amount',
    }).ScopeField(fieldName);

    if (!item.amounts || item.amounts.length === 0) errors.push(error_msg);

    if (item.amounts) {
      if (item.amounts.length != contractTermsForItems.length) errors.push(error_msg);

      const matchingPromo = contractTermsForItems[i];
      const field = `promo_${matchingPromo?.termTypeId || 0}_${i}`;

      if (!matchingPromo) continue;

      const amount = item.amounts[i];
      //Amounts for non matching random weights in a disabled cell have to be zero.
      if (referenceData?.termTypeUnitsOfMeasure[matchingPromo.termUnitOfMeasureId].forRandomWeight !== item.item?.isRandomWeight && amount !== 0) {
        errors.push(error_msg);
      }

      // == and not === since it could be undefined or null
      if (amount == undefined) {
        errors.push(ValidationError.Required({ field: field, identifier: item.uniqueId, displayField: 'Amount' }).ScopeField(fieldName));
      } else if (amount > MAX_AMOUNT) {
        errors.push(
          ValidationError.LessThanOrEqual({
            input: 'Amount',
            comparison: NumberToCurrencyString(MAX_AMOUNT),
            field: field,
            identifier: item.uniqueId,
          }).ScopeField(fieldName)
        );
      }
    }
  }

  return errors;
};

const validateContractItemCaseListCost = (
  item: IContractItem,
  contractTermsForItems: IContractTermForItem[],
  contractTermsForLumpSum: IContractTermForLumpSum[],
  fieldName: string
): ValidationError[] => {
  const errors: ValidationError[] = [];

  if (contractTermsForItems.length > 0) {
    if (item.caseListCost == undefined || item.caseListCost == null || item.caseListCost.toString() == '')
      errors.push(
        ValidationError.Required({
          field: 'CaseListCost',
          identifier: item.uniqueId,
          displayField: 'Case List Cost',
        }).ScopeField(fieldName)
      );
    else if (item.caseListCost > MAX_AMOUNT)
      errors.push(
        ValidationError.LessThanOrEqual({
          input: 'CaseListCost',
          comparison: NumberToCurrencyString(MAX_AMOUNT),
          field: 'CaseListCost',
          identifier: item.uniqueId,
        }).ScopeField(fieldName)
      );
  }
  if (contractTermsForLumpSum.length > 0) {
    if (item.caseListCost == undefined || item.caseListCost.toString() == '')
      errors.push(
        ValidationError.Required({
          field: 'CaseListCost',
          identifier: item.uniqueId,
          displayField: 'Case List Cost',
        }).ScopeField(fieldName)
      );
    else if (item.caseListCost > MAX_AMOUNT)
      errors.push(
        ValidationError.LessThanOrEqual({
          input: 'CaseListCost',
          comparison: NumberToCurrencyString(MAX_AMOUNT),
          field: 'CaseListCost',
          identifier: item.uniqueId,
        }).ScopeField(fieldName)
      );
  }
  return errors;
};

const validateContractItemSuggestedRetailPrice = (item: IContractItem, fieldName: string): ValidationError[] => {
  const errors: ValidationError[] = [];

  if (item.suggestedRetailPrice !== undefined && item.suggestedRetailMultiple !== null && item.suggestedRetailPrice > MAX_AMOUNT)
    errors.push(
      ValidationError.LessThanOrEqual({
        input: 'Suggested Retail Price',
        comparison: NumberToCurrencyString(MAX_AMOUNT),
        field: 'SuggestedRetailPrice',
        identifier: item.uniqueId,
      }).ScopeField(fieldName)
    );
  else if (item.suggestedRetailMultiple !== undefined && item.suggestedRetailMultiple !== null && item.suggestedRetailMultiple <= 0)
    errors.push(
      ValidationError.GreaterThan({
        input: 'Suggested Retail Multiple',
        comparison: '0',
        field: 'SuggestedRetailMultiple',
        identifier: item.uniqueId,
      }).ScopeField(fieldName)
    );
  else if (
    item.suggestedRetailMultiple != null &&
    item.suggestedRetailMultiple > 0 &&
    (item.suggestedRetailPrice == null || item.suggestedRetailPrice == 0)
  )
    errors.push(
      ValidationError.Required({
        field: 'SuggestedRetailPrice',
        identifier: item.uniqueId,
        displayField: 'Suggested Retail Price',
      }).ScopeField(fieldName)
    );

  return errors;
};

const validateContractItem = (
  item: IContractItem,
  contractTermsForItems: IContractTermForItem[],
  contractTermsForLumpSum: IContractTermForLumpSum[],
  parentFieldName?: string,
  referenceData?: IReferenceDataState
): ValidationError[] => {
  const fieldName = 'ContractItem';
  const errors: ValidationError[] = [];

  if (!item) {
    errors.push(ValidationError.Required({ field: fieldName, displayField: 'Contract Item' }).ScopeField(parentFieldName));
    return errors;
  }

  if (item.sku <= 1) errors.push(ValidationError.GreaterThan({ input: 'SKU', comparison: '1', identifier: item.uniqueId }).ScopeField(fieldName));

  if (contractTermsForItems.length > 0) {
    errors.push(...validateContractItemAmounts(item, contractTermsForItems, fieldName, referenceData));
    errors.push(...validateItemForTerms(item, contractTermsForItems, item.amounts));
  }

  errors.push(...validateContractItemCaseListCost(item, contractTermsForItems, contractTermsForLumpSum, fieldName));
  errors.push(...validateContractItemSuggestedRetailPrice(item, fieldName));

  return errors;
};

const validateItemForTerms = (
  contractItem: IContractItem,
  contractTermsForItems: IContractTermForItem[],
  amounts: (number | undefined)[]
): ValidationError[] => {
  const errors: ValidationError[] = [];
  const item = contractItem.item;
  if (!item) return [];

  if (item?.discontinuedDate) {
    for (let i = 0; i < contractTermsForItems.length; i++) {
      const term = contractTermsForItems[i];
      if (amounts.length > i) {
        const amount = amounts[i];
        if (amount && amount > 0 && item.discontinuedDate && term.startDate && term.startDate >= item.discontinuedDate)
          errors.push(
            new ValidationError({
              message: `Item ${item.upc} is discontinued on ${item.discontinuedDate.toDateString()} before the promotional period.`,
              field: 'item.upc',
              identifier: contractItem.uniqueId,
            })
          );
      }
    }
  }

  return errors;
};

const validateContractItems = (
  items: IContractItem[],
  contractTermsForItem: IContractTermForItem[],
  contractTermsForLumpSum: IContractTermForLumpSum[],
  referenceData?: IReferenceDataState
): ValidationError[] => {
  const fieldName = 'ContractItems';

  const errors: ValidationError[] = [];

  for (const item of items) {
    errors.push(...validateContractItem(item, contractTermsForItem, contractTermsForLumpSum, fieldName, referenceData));
  }

  return errors;
};

const validateItemCounts = (
  contractTermsForItem: IContractTermForItem[],
  contractTermsForLumpSum: IContractTermForLumpSum[],
  contractItems: IContractItem[]
): ValidationError[] => {
  const errors: ValidationError[] = [];

  if (contractTermsForItem.length === 0 && contractTermsForLumpSum.length === 0)
    errors.push(new ValidationError({ message: `At least one promotion is required.` }));

  if (contractTermsForItem.length > 0 && contractItems.length === 0) errors.push(new ValidationError({ message: `At least one item is required.` }));

  return errors;
};

const gatherErrors = async (...args: any[]): Promise<ValidationError[] | undefined> => {
  const results = await Promise.all(args);
  const errors = results.filter((q) => q).flatMap((q) => q);

  return errors.length > 0 ? errors : undefined;
};

const validateContract = async (contractTerms: IContractTerms, referenceData?: IReferenceDataState): Promise<ValidationError[] | undefined> => {
  if (!referenceData) return [new ValidationError({ message: `referenceData not populated.` })];

  const categoryManagers = Object.values(referenceData.categoryManagers);
  const departments = Object.values(referenceData.departments);
  const termTypes = Object.values(referenceData.termTypes);

  const errors = await gatherErrors(
    validateVendorContractNumber(contractTerms.vendorContractNumber),
    validateCustomer(contractTerms.customer?.customerId),
    validateManufacturer(contractTerms.manufacturer?.manufacturerId),
    validateCategoryManager(categoryManagers, contractTerms.categoryManager?.id),
    validateContractTermsForLumpSum(contractTerms.contractTermsForLumpSum, departments, termTypes),
    validateContractTermsForItem(contractTerms.contractTermsForItem),
    validateContractItems(contractTerms.contractItems, contractTerms.contractTermsForItem, contractTerms.contractTermsForLumpSum, referenceData),
    validateItemCounts(contractTerms.contractTermsForItem, contractTerms.contractTermsForLumpSum, contractTerms.contractItems)
  );

  return errors;
};

export const validationService = {
  validateContract,
};
