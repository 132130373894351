import { IContractAdPlannerExportContainer, IPricing } from '../../models';
import { fetchGetJson, fetchPostJson, HttpErrorResponse, toDateOnlyString } from '../contractHubApi';
import { IContractAdPalnnerExportRequest } from '../../models/requests';

export const contractAdPlannerExportService = {
  getAdPlannerExport,
  submitAdPlannerExport,
};

async function getAdPlannerExport(
  contractId: number,
  startDate: Date,
  adSiteId: number
): Promise<IContractAdPlannerExportContainer | HttpErrorResponse> {
  const response = await fetchGetJson<IContractAdPlannerExportContainer>({
    endpoint: `contract/${contractId}/ap`,
    query: { startDate: toDateOnlyString(startDate), adSiteId },
  });
  return response;
}

function submitAdPlannerExport(contractId: number, request: IContractAdPalnnerExportRequest): Promise<number | HttpErrorResponse> {
  return fetchPostJson<number>({ endpoint: `contract/${contractId}/ap`, body: request });
}
