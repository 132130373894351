import { IContract, IPricing, IContractItem, IChildContractItem, IPricingItemTermItem } from '../../../../../models';
import { format } from 'date-fns';
import { Button, DataGridRenderBodyCellParams } from '@dierbergs-markets/react-component-library';
import * as PG from '@dierbergs-markets/react-feature-library';
import { tss } from 'tss-react';
import { CurrencyCellContent, PricingGrid } from '@dierbergs-markets/react-feature-library';
import { memo, useEffect, useState } from 'react';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import { defaultColors } from '../../../../../styles/variables';

interface PricingGridPricingItem extends PG.IPricingItem {
  suggestedRetailPrice?: number;
  suggestedRetailMultiple?: number;
}

interface IProps {
  contract: IContract;
  canModify: boolean;
  contractLoading: boolean;
  onPricingEdit: (price: IPricing) => void;
  onRowUpdate: (row: PG.IPricingItem) => void;
  onMultiRowUpdate: (rows: PG.IPricingItem[]) => void;
}

const PricingGridContainer = memo((props: IProps) => {
  // STATE
  const [gridRows, setGridRows] = useState<PricingGridPricingItem[]>([]);

  // MISC. HOOKS
  const { referenceData, user } = useApplicationContextState();
  const { classes } = useStyles();

  // LOCAL VARS

  //EFFECTS
  useEffect(() => {
    setGridRows(props.contract.terms.contractItems.map((ci) => toPricingGridPricingItem(ci)));
  }, [props.contract.terms.contractItems, props.contract.pricings, referenceData]);

  //FUNCTIONS
  function getCostImplicationType(unitOfMeasureId: number) {
    const uom = referenceData?.termTypeUnitsOfMeasure[unitOfMeasureId];

    switch (uom?.name.toUpperCase()) {
      case 'CASE':
        return PG.CostImplicationType.CASE;

      default:
        return PG.CostImplicationType.SINGLEUNIT;
    }
  }

  function getPricings() {
    if (!referenceData || !props.contract.pricings) return [];

    return props.contract.pricings.map<PG.IPricingColumnConfiguration<PG.IPricingItem>>((p) => ({
      name: `pricing_${p.pricingId ?? p.uniqueId}`,
      priceType: p.priceTypeId,
      startDate: p.startDate,
      endDate: p.endDate,
      comments: p.comments,
      groupedHeaderCellCss: {
        root: {
          marginTop: '10px',
          height: '150px',
          backgroundColor: defaultColors.white,
          borderRadius: '16px 16px 0 0',
          outline: `1px solid ${defaultColors.grey}`,
          fontSize: '13px',
          color: defaultColors.mediumGrey,
        },
        subCell: {
          borderTop: 'unset',
        },
      },
      renderHeaderCellContent: () => {
        return (
          <div className={classes.headerContents}>
            <Button classes={{ root: classes.headerEditBtn }} id={`editPricing${p.uniqueId}`} onClick={() => invokePricingEdit(p)} variant="link">
              {referenceData.priceTypes[p.priceTypeId].name}
            </Button>
            <div>
              {format(p.startDate, 'M/d/yy')} - {format(p.endDate, 'M/d/yy')}
            </div>
            <div title={p.itemTerms.map((it) => referenceData.termTypes[it.termTypeId].name).join(', ')}>
              <span style={{ fontWeight: 700, color: defaultColors.black }}>*WIP</span> Cost Implications: {p.itemTerms.length}
            </div>
            <div className={classes.headerComments} title={p.comments}>
              Comments: {p.comments}
            </div>
          </div>
        );
      },
      costImplications: p.itemTerms
        .filter((it) => {
          //if same contract, and promotion doesn't exist (deleted during current session), don't render the column
          if (it.contract.contractId === props.contract.contractId) {
            const termExists = props.contract.terms.contractTermsForItem.some((cti) => cti.uniqueId === it.itemTermUniqueId);
            return termExists;
          }
          return true;
        })
        .map<PG.ICostImplicationConfiguration>((it) => ({
          uniqueId: it.itemTermUniqueId,
          name: referenceData.termTypes[it.termTypeId].name,
          accountingType: PG.AccountingType.CREDIT,
          type: getCostImplicationType(it.termUnitOfMeasureId),
          renderHeaderCellContent: () => {
            return (
              <>
                <span style={{ fontWeight: 700, color: defaultColors.black }}>*WIP </span>
                <span
                  title={`Internal Contract # ${it.contract.contractId} - ${referenceData.termTypes[it.termTypeId].name} / ${it.itemTermUniqueId}`}
                >
                  {referenceData.termTypes[it.termTypeId].name}
                </span>
              </>
            );
          },
        })),
    }));
  }

  function toPricingGridPricingItem(contractItem: IContractItem, isChildItem?: boolean): PricingGridPricingItem {
    if (!contractItem.item) throw new Error(`${isChildItem ? 'Child ' : ''} Contract Item (${contractItem.sku}) - Item property is null.`);

    const result: PricingGridPricingItem = {
      rowType: isChildItem ? 1 : 0,
      item: toPricingGridItem(contractItem),
      suggestedRetailPrice: contractItem.suggestedRetailPrice,
      suggestedRetailMultiple: contractItem.suggestedRetailMultiple,
      costImplications: createCostImplications(contractItem),
      prices: extractPricesBySku(contractItem.item.sku),
      currentRegPrice: { multiple: contractItem.item.regularMultiple, price: contractItem.item.regularPrice },
      childData: isChildItem ? [] : contractItem.childData && contractItem.childData.map((ci) => toPricingGridPricingItem(ci, true)),
    };
    return result;
  }

  function createCostImplications(contractItem: IContractItem | IChildContractItem): PG.ICostImplication[] {
    if (!props.contract.pricings) return [];

    return props.contract.pricings
      .flatMap((x) => x.itemTerms)
      .reduce((acc: PG.ICostImplication[], itemTerm) => {
        //prevent duplicate cost implications from appearing in the grid.
        if (!acc.some((term) => term.uniqueId === itemTerm.itemTermUniqueId)) {
          acc.push(toPricingGridCostImplication(itemTerm.itemTermUniqueId, contractItem.sku, itemTerm.items));
        }
        return acc;
      }, []);
  }

  function toPricingGridCostImplication(itemTermUniqueId: string, sku: number, itemTermItems: IPricingItemTermItem[]): PG.ICostImplication {
    const item = itemTermItems.find((i) => i.sku === sku);

    if (item) return { uniqueId: itemTermUniqueId, amount: item?.amount ?? 0 };

    //check if the itemTermUniqueId is on this contract.
    const localContractTermIndex = props.contract.terms.contractTermsForItem.findIndex((cti) => cti.uniqueId === itemTermUniqueId);

    const isLocalContractTerm = localContractTermIndex !== -1;

    if (isLocalContractTerm) {
      //If the contractItem exists but has not been included in itemTermItems, get the amount from contractItem.amounts array
      //This happens when new UPCs are added to the same contract during the current session but not saved yet.
      if (isLocalContractTerm) {
        const localContractItem = props.contract.terms.contractItems.find((ci) => ci.sku === sku);
        if (localContractItem) return { uniqueId: itemTermUniqueId, amount: localContractItem.amounts[localContractTermIndex] ?? 0 };
      }
    }
    return { uniqueId: itemTermUniqueId, amount: 0 };
  }

  function toPricingGridItem(contractItem: IContractItem): PG.IItem {
    if (!contractItem.item) throw new Error('Item not defined on contractItem.');
    return {
      packSize: contractItem.item.quantityPerPack,
      sku: contractItem.item.sku,
      upc: contractItem.item.upc,
      description: contractItem.item.description,
      displaySize: `${contractItem.item.size} ${contractItem.item.unitOfMeasure}`,
      priceAssociation: contractItem.item.priceAssociation,
      caseCost: contractItem.caseListCost,
    };
  }

  function extractPricesBySku(sku: number): (PG.IPrice | undefined)[] {
    if (!props.contract.pricings) return [];
    const result: (PG.IPrice | undefined)[] = [];

    props.contract.pricings.forEach((price) => {
      const itemPrice = price.items.find((i) => i.sku === sku);

      result.push(itemPrice ? { multiple: itemPrice.multiple, price: itemPrice.price } : undefined);
    });
    return result;
  }

  function invokePricingEdit(pricing: IPricing) {
    props.canModify && props.onPricingEdit(pricing);
  }

  return (
    <div className={classes.root}>
      {referenceData && gridRows.length > 0 && (
        <PricingGrid
          id={'PricingGrid'}
          prices={getPricings()}
          findReplaceEnabled={true}
          columnOrderable
          maxBodyHeightOffset={496}
          headerRowHeight={160}
          rows={gridRows}
          actionConfig={[{ position: 'bottom', actions: ['Columns', 'Sort'], alignment: 'left' }]}
          processRowUpdate={props.onRowUpdate}
          processMultiRowUpdate={props.onMultiRowUpdate}
          preferences={{
            columns: {
              visibilityMap: {
                suggestedRetail: true,
              },
            },
            columnOrder: {
              suggestedRetail: 10.1,
            },
          }}
          additionalColumns={[
            {
              field: 'suggestedRetail',
              headerName: 'Sugg Retail',
              type: 'string',
              width: 95,
              renderBodyCellContent(params: DataGridRenderBodyCellParams<number, PricingGridPricingItem>) {
                if (!params.row.suggestedRetailPrice) return <></>;
                return <CurrencyCellContent value={params.row.suggestedRetailPrice} multiple={params.row.suggestedRetailMultiple} />;
              },
            },
          ]}
          costImplicationGroupedHeaderCellCss={{
            root: {
              backgroundColor: defaultColors.white,
              borderRadius: '16px 16px 0 0',
              outline: `1px solid ${defaultColors.grey}`,
              fontSize: '16px',
              paddingTop: '13px',
              height: '150px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            },
            subRow: {
              marginTop: '10px',
            },
          }}
          exportOverrideOptions={{ userName: user?.userName ?? '', defaultFileName: `Pricing-${props.contract.contractId}` }}
        />
      )}
    </div>
  );
});

export default PricingGridContainer;

const useStyles = tss.withParams().create({
  root: {},

  headerContents: {
    margin: '15px',
    marginBottom: '10px',
    marginLeft: '20px',
    width: '100%',
  },
  headerComments: {
    width: '280px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerEditBtn: {
    '&&': {
      fontWeight: 500,
      lineHeight: '19px',
      fontSize: '16px',
      color: defaultColors.blue,
      paddingBottom: '5px',
    },
  },
  suggestedRetail: {
    paddingTop: '8px',
  },
  pricePrefix: {
    paddingRight: '8px',
    fontWeight: 500,
  },
});
