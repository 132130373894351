import React, { createContext, useContext, useEffect, useState } from 'react';
import environment from 'environment';
import {
  IUser,
  EntityById,
  IStore,
  ICategoryManager,
  ITermUnitOfMeasure,
  ITermType,
  IDepartment,
  IReferenceData,
  IStoreGroup,
  ITaskAssigneeDisplay,
  IContractNotification,
  IPriceType,
  IAdSite,
} from '../models';
import { HubConnectionBuilder, HubConnection, HubConnectionState } from '@microsoft/signalr';
import { useNavigate } from 'react-router-dom';
import { RouteEnum } from '../views/layout/PageRouter';

export interface IReferenceDataState {
  stores: EntityById<IStore>;
  storeGroups: EntityById<IStoreGroup>;
  categoryManagers: EntityById<ICategoryManager>;
  taskAssigneeDisplays: EntityById<ITaskAssigneeDisplay>;
  termTypes: EntityById<ITermType>;
  priceTypes: EntityById<IPriceType>;
  digitalRewardTermTypeId?: number;
  termTypeUnitsOfMeasure: EntityById<ITermUnitOfMeasure>;
  departments: EntityById<IDepartment>;
  rewardPointsMultipliers: { value: any; text: string }[];
  pricingEnabled: boolean;
  adPlannerExportEnabled: boolean;
  adSites: EntityById<IAdSite>;
}

export interface IApplicationContextState {
  user?: IUser;
  referenceData?: IReferenceDataState;
  contractNotifications: IContractNotification[];
  loginInProgress?: boolean;
}

export interface IApplicationContextProvider extends IApplicationContextState {
  setUser?: (user?: IUser) => void;
  setReferenceData?: (referenceData: IReferenceData) => void;
  setContractNotifications?: (notifications: IContractNotification[]) => void;
  setLoginInProgress?: (value: boolean) => void;
  setPricingEnabled?: (value: boolean) => void;
  setAdPlannerExportEnabled?: (value: boolean) => void;
}

const ApplicationContext = createContext<IApplicationContextProvider>({ contractNotifications: [] });

interface IUserHubUpdatePermissionsRequest {
  permissions: string[];
}

interface IProps {
  children?: React.ReactNode;
}
const ApplicationContextProvider = (props: IProps) => {
  const [state, setState] = useState<IApplicationContextState>({ contractNotifications: [] });
  const [userHubConnection, setUserHubConnection] = useState<HubConnection | null>(null);
  const [connectionUserId, setConnectionUserId] = useState<number | null>(null);
  const navigate = useNavigate();

  const provider: IApplicationContextProvider = {
    ...state,
    setUser: (user?: IUser) =>
      setState((s) => {
        return { ...s, user: user };
      }),
    setReferenceData: (referenceData?: IReferenceData) =>
      setState((s) => {
        const departments: EntityById<IDepartment> = {};
        const termTypeUnitsOfMeasure: EntityById<ITermUnitOfMeasure> = {};
        const termTypes: EntityById<ITermType> = {};
        const priceTypes: EntityById<IPriceType> = {};
        const stores: EntityById<IStore> = {};
        const storeGroups: EntityById<IStoreGroup> = {};
        const categoryManagers: EntityById<ICategoryManager> = {};
        const taskAssigneeDisplays: EntityById<ITaskAssigneeDisplay> = {};
        const adSites: EntityById<IAdSite> = {};

        referenceData?.categoryManagers.forEach((x) => (categoryManagers[x.id] = x));
        referenceData?.departments.forEach((x) => (departments[x.id] = x));
        referenceData?.termTypes.forEach((x) => (termTypes[x.termTypeId] = x));
        referenceData?.priceTypes?.forEach((x) => (priceTypes[x.id] = x));
        referenceData?.unitsOfMeasure.forEach((x) => (termTypeUnitsOfMeasure[x.termUnitOfMeasureId] = x));
        referenceData?.stores.forEach((x) => (stores[x.id] = x));
        referenceData?.storeGroups.forEach((x) => (storeGroups[x.id] = x));
        referenceData?.taskAssignees.forEach((x) => (taskAssigneeDisplays[x.taskAssigneeId] = x));
        referenceData?.adSites.forEach((x) => (adSites[x.id] = x));
        return {
          ...s,
          referenceData: {
            categoryManagers,
            departments,
            priceTypes,
            termTypes,
            termTypeUnitsOfMeasure,
            stores,
            storeGroups,
            taskAssigneeDisplays,
            rewardPointsMultipliers: referenceData?.rewardPointsMultipliers.map((a) => ({ value: a, text: `${a}X` })) ?? [],
            digitalRewardTermTypeId: referenceData?.termTypes.find((a) => a.validationTypeCode === 'DIGITAL_REWARDS')?.termTypeId,
            pricingEnabled: referenceData?.pricingEnabled ?? true,
            adPlannerExportEnabled: referenceData?.adPlannerExportEnabled ?? true,
            adSites,
          },
        };
      }),
    setContractNotifications: (notifications: IContractNotification[]) =>
      setState((s) => {
        return {
          ...s,
          contractNotifications: notifications,
        };
      }),
    setLoginInProgress: (value: boolean) =>
      setState((s) => {
        return { ...s, loginInProgress: value };
      }),
    setPricingEnabled: (value: boolean) =>
      setState((s) => {
        return { ...s, pricingEnabled: value };
      }),
    setAdPlannerExportEnabled: (value: boolean) =>
      setState((s) => {
        return { ...s, adPlannerExportEnabled: value };
      }),
  };

  useEffect(() => {
    if (userHubConnection && userHubConnection.state === HubConnectionState.Connected) return;
    const webSocketUrl = `${environment.baseApiUrl}/ws/user`;

    const connection = new HubConnectionBuilder().withUrl(webSocketUrl).withAutomaticReconnect().build();
    connection.on('Logout', handleSignalRLogoutRequest);
    connection.on('UpdatePermissions', handleSignalRUpdatePermissionsRequest);
    setUserHubConnection(connection);

    return function () {
      connection.stop();
    };
  }, []);

  useEffect(() => {
    (async () => {
      if (!userHubConnection) return;

      const currentUserId = state.user?.id;
      if (!currentUserId || currentUserId !== connectionUserId) {
        if (userHubConnection.state != HubConnectionState.Disconnected) {
          await userHubConnection.stop();
        }
      }
      if (!currentUserId || currentUserId === connectionUserId) return;

      try {
        await userHubConnection.start();
      } catch (ex) {
        //console.log('user signalr start exception', ex);
      }
      setConnectionUserId(currentUserId);
    })();
  }, [userHubConnection, state.user?.id]);

  function handleSignalRLogoutRequest() {
    setState((s) => {
      return {
        ...s,
        user: undefined,
      };
    });
    navigate(RouteEnum.Dashboard);
  }

  function handleSignalRUpdatePermissionsRequest(request: IUserHubUpdatePermissionsRequest) {
    const updatedUser = state.user
      ? ({
          ...state.user,
          permissions: request.permissions,
        } as IUser)
      : undefined;

    setState((s) => {
      return {
        ...s,
        user: updatedUser,
      };
    });
  }

  return <ApplicationContext.Provider value={provider}>{props.children}</ApplicationContext.Provider>;
};

//Convience hook for retrieving ApplicationContext state. Elimates the need to import useContext and ApplicationContext in a component that needs the state.
const useApplicationContextState = () => {
  // get the context
  const context = useContext(ApplicationContext);

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error('ApplicationContext was used outside of its Provider');
  }

  return context;
};

export { ApplicationContext, ApplicationContextProvider, useApplicationContextState };
