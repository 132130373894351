import { useSnackbar } from 'notistack';
import { useEffect, useState, memo } from 'react';
import { format } from 'date-fns';
import { Dayjs } from 'dayjs';
import { MdStore, MdOutlineStore } from 'react-icons/md';
import { DateRange } from '@mui/x-date-pickers-pro';
import { v4 } from 'uuid';
import { IStoreSelection, IPricingItemTerm, IContract, IStore, IPricingItemTermContract } from '../../../../../models';
import {
  Button,
  CoreDialog,
  CoreDialogActions,
  CoreDialogContent,
  CoreDialogTitle,
  DataGrid,
  DataGridColDef,
  DataGridRenderBodyCellParams,
  DataGridRowId,
  DateRangePickerInput,
  Popover,
  Select,
  Checkbox,
  TextInput,
} from '@dierbergs-markets/react-component-library';
import { ArrayUtils } from '../../../../../utilities/ArrayUtility';
import { tss } from 'tss-react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useApplicationContextState } from '../../../../../contexts/ApplicationContext';
import Joi from 'joi';
import { joiResolver } from '@hookform/resolvers/joi';
import { pricingService } from '../../../../../services';
import { IPricingSearchResponse } from '../../../../../models/responses';
import { IPricingSearchRequest } from '../../../../../models/requests';
import { Link } from 'react-router-dom';
import { RouteEnum } from '../../../../layout/PageRouter';
import { defaultColors } from '../../../../../styles/variables';
import { HttpErrorResponse } from '../../../../../services/contractHubApi';
import { alpha } from '@mui/material';

export interface IPricingModalState {
  pricingId?: number;
  uniqueId: string;
  priceTypeId: number;
  dateRange: DateRange<Dayjs>;
  comments: string;
  itemTerms: IPricingItemTerm[];
}

interface ICostImplicationGridRow extends IPricingItemTerm {
  minAmount: number;
  maxAmount: number;
  /** Is valid based on current pricing criteria as set in this modal. */
  isValid: boolean;
}
type AmountRange = { min: number | undefined; max: number | undefined };

interface IProps {
  id: string;
  contract: IContract;
  open: boolean;
  initialState: Partial<IPricingModalState>;
  onSave: (pricingState: IPricingModalState) => void;
  onCancel: () => void;
  onDelete: () => void;
}

const PricingModal = memo((props: IProps) => {
  //STATE
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [priceTypeOptions, setPriceTypeOptions] = useState<any[]>([]);
  const [costImplications, setCostImplications] = useState<ICostImplicationGridRow[]>([]);
  const [selectedItemTermUniqueIds, setSelectedItemTermUniqueIds] = useState<DataGridRowId[]>([]);

  //MISC. HOOKS
  const { referenceData } = useApplicationContextState();
  const { enqueueSnackbar } = useSnackbar();
  const { classes, css, cx } = useStyles();

  // LOCAL VARS

  const isNewForm = props.initialState?.pricingId === undefined;
  const defaultValues: Partial<IPricingModalState> = { ...props.initialState };

  const pricingSchema = {
    priceTypeId: Joi.number().required().messages({ 'any.required': 'Price type is required.' }),
    dateRange: Joi.required()
      .messages({ 'any.required': 'Date range is required.' })
      .custom((value: DateRange<Dayjs>, helpers) => {
        if (!value[0]) return helpers.message({ custom: 'Start date is required.' });
        if (!value[1]) return helpers.message({ custom: 'End date is required.' });

        if (!value[0].isValid()) return helpers.message({ custom: 'Start date is not valid.' });
        if (!value[1].isValid()) return helpers.message({ custom: 'End date is not valid.' });

        if (value[0] > value[1]) {
          return helpers.message({ custom: 'Start date greater than end date.' });
        }
        return value;
      }),
  };

  //'unknown()' tells Joi to ignore properties that aren't part of the validation schema - e.g., contractId, uniqueId, etc...
  const validationSchema = Joi.object(pricingSchema).unknown();

  const {
    handleSubmit,
    control,
    watch,
    clearErrors,
    trigger,
    formState: { errors },
  } = useForm<IPricingModalState>({ defaultValues, resolver: joiResolver(validationSchema) });

  const onSubmit: SubmitHandler<IPricingModalState> = (data: IPricingModalState) => handleSave(data);

  const gridColumns: DataGridColDef[] = [
    {
      field: 'checkboxselection',
      type: 'string',
      width: 45,
      renderBodyCellContent(params: DataGridRenderBodyCellParams<number, ICostImplicationGridRow>) {
        return (
          <Checkbox
            id={`cbx)${params.row.itemTermUniqueId}`}
            disabled={!params.row.isValid}
            checked={selectedItemTermUniqueIds.includes(params.row.itemTermUniqueId)}
            onChange={(checked) => {
              const selections = [...selectedItemTermUniqueIds];
              if (checked) {
                if (!selections.includes(params.row.itemTermUniqueId)) {
                  selections.push(params.row.itemTermUniqueId);
                  setSelectedItemTermUniqueIds(selections);
                }
              } else {
                setSelectedItemTermUniqueIds(selections.filter((i) => i !== params.row.itemTermUniqueId));
              }
            }}
          />
        );
      },
    },
    {
      field: 'contract',
      type: 'string',
      headerName: 'Internal Contract #',
      width: 150,
      renderBodyCellContent(params: DataGridRenderBodyCellParams<IPricingItemTermContract, ICostImplicationGridRow>) {
        if (!params.value) return '';

        return params.value.contractId !== props.contract.contractId ? (
          <Link to={`${RouteEnum.Contract}/${params.value.contractId}`} target={`_blank`} id={`lnkOpenContractInNewWindow${params.value.contractId}`}>
            {params.value.contractId}
          </Link>
        ) : (
          <div>{params.value.contractId}</div>
        );
      },
    },
    {
      field: 'startDate',
      type: 'string',
      headerName: 'Start Date',
      width: 100,
      renderBodyCellContent: (params: DataGridRenderBodyCellParams<Date, ICostImplicationGridRow>) =>
        params.value && format(params.value, 'MM/dd/yy'),
    },
    {
      field: 'endDate',
      type: 'string',
      headerName: 'End Date',
      renderBodyCellContent: (params: DataGridRenderBodyCellParams<Date, ICostImplicationGridRow>) =>
        params.value && format(params.value, 'MM/dd/yy'),
      width: 100,
    },
    {
      field: 'contract.stores',
      type: 'string',
      headerName: 'Stores',
      width: 75,
      renderBodyCellContent: (params: DataGridRenderBodyCellParams<IStoreSelection, ICostImplicationGridRow>) => {
        if (!params.value) return 'N/A';
        return (
          <Popover
            id={'stores'}
            anchorOrigin={{ vertical: 'center', horizontal: 'left' }}
            transformOrigin={{ vertical: 'center', horizontal: 'right' }}
            buttonProps={{
              variant: 'icon',
              children: <StoreIcon stores={params.value ?? { storeGroupIds: [], storeIds: [] }} />,
              classes: {
                root: css({
                  '&&:hover': { backgroundColor: 'unset' },
                }),
              },
            }}
            classes={{
              popoverContent: css(classes.storesPopoverContent),
            }}
          >
            <StorePopoverContent stores={params.value ?? { storeGroupIds: [], storeIds: [] }} />
          </Popover>
        );
      },
    },
    {
      field: 'amounts',
      type: 'string',
      headerName: 'Amount',
      width: 150,
      renderBodyCellContent: (params: DataGridRenderBodyCellParams<string, ICostImplicationGridRow>) => {
        if (params.row.minAmount === params.row.maxAmount)
          return `$${params.row.minAmount.toFixed(2)}`; //pick either
        else {
          return `$${params.row.minAmount.toFixed(2)} - $${params.row.maxAmount.toFixed(2)}`;
        }
      },
    },
    {
      field: 'termTypeId',
      type: 'string',
      headerName: 'Term Type',
      flex: 1,
      renderBodyCellContent(params: DataGridRenderBodyCellParams<number, ICostImplicationGridRow>) {
        return referenceData && params.value && referenceData.termTypes[params.value].name;
      },
    },
    {
      field: 'termUnitOfMeasureId',
      type: 'string',
      headerName: 'Unit of Measure',
      width: 200,
      renderBodyCellContent(params: DataGridRenderBodyCellParams<number, ICostImplicationGridRow>) {
        return referenceData && params.value && referenceData.termTypeUnitsOfMeasure[params.value].name;
      },
    },
  ];

  //EFFECTS

  useEffect(() => {
    if (!referenceData) return;

    const options: any[] = [];

    ArrayUtils.orderBy(Object.values(referenceData.priceTypes), (x) => x.name).map((i) => {
      options.push({ text: `${i.name}`, value: i.id });
    });

    setPriceTypeOptions(options);
    clearErrors();
  }, [referenceData, watch('priceTypeId')]);

  useEffect(() => {
    let initialImplications: ICostImplicationGridRow[] = [];
    if (props.initialState.itemTerms) {
      initialImplications = props.initialState.itemTerms.map(toCostImplicationGridRow);
    }
    setSelectedItemTermUniqueIds(initialImplications.map((i) => i.itemTermUniqueId));
    setCostImplications([...initialImplications]);
  }, []);

  //ASYNC FUNCTIONS

  async function handleFindImplications() {
    const dateRangeIsValid = await trigger('dateRange'); //validates dateRange field only
    if (!dateRangeIsValid) return;

    setIsSearching(true);
    clearErrors();

    const startDate = watch('dateRange.0')?.toDate();
    const endDate = watch('dateRange.1')?.toDate();

    if (startDate && endDate) {
      const request: IPricingSearchRequest = {
        startDate,
        endDate,
        contractId: props.contract.contractId,
        contractStores: props.contract.terms.stores,
        contractItems: props.contract.terms.contractItems.map((ci) => ({ sku: ci.sku, amounts: ci.amounts })),
        contractTermsForItems: props.contract.terms.contractTermsForItem.map((cti) => ({
          uniqueId: cti.uniqueId,
          startDate: cti.startDate,
          endDate: cti.endDate,
        })),
      };
      const response = await pricingService.getImplications(request);

      if (response instanceof HttpErrorResponse) {
        enqueueSnackbar('Unable to retrieve search results.', { variant: 'error' });
      } else {
        applyImplicationResultsToGrid(response);
      }
      setIsSearching(false);
    }
  }

  //FUNCTIONS

  function getAmountRange(amounts: (number | undefined)[]) {
    const amountRange: AmountRange = amounts.reduce(
      (acc: AmountRange, cur) => {
        return {
          min: acc.min === undefined ? cur : Math.min(acc.min, cur ?? 0),
          max: acc.max === undefined ? cur : Math.max(acc.max, cur ?? 0),
        };
      },
      { min: undefined, max: undefined }
    );

    return amountRange;
  }

  function applyImplicationResultsToGrid(response: IPricingSearchResponse) {
    const latestLocalContractTerms = response.contractItemTermUniqueIds.reduce((accRows: ICostImplicationGridRow[], value) => {
      const promoIndexPosition = props.contract.terms.contractTermsForItem.findIndex((cti) => cti.uniqueId === value);
      if (promoIndexPosition !== -1) {
        const term = props.contract.terms.contractTermsForItem[promoIndexPosition];

        const allPromoAmounts = props.contract.terms.contractItems.map((ci) => ci.amounts[promoIndexPosition]);
        const amountRange = getAmountRange(allPromoAmounts);

        accRows.push({
          contract: {
            contractId: props.contract.contractId,
            stores: props.contract.terms.stores,
          },
          itemTermUniqueId: term.uniqueId,
          termTypeId: term.termTypeId!,
          termUnitOfMeasureId: term.termUnitOfMeasureId,
          minAmount: amountRange.min ?? 0,
          maxAmount: amountRange.max ?? 0,
          items: [],
          startDate: term.startDate,
          endDate: term.endDate,
          isValid: true,
        });
      }
      return accRows;
    }, []);

    const latestOverlappingContractsTerms = response.overlappingItemTerms.map<ICostImplicationGridRow>((o) => {
      const amountRange = getAmountRange(o.items.map((x) => x.amount));

      return {
        contract: o.contract,
        itemTermUniqueId: o.itemTermUniqueId,
        startDate: o.startDate,
        endDate: o.endDate,
        termTypeId: o.termTypeId,
        termUnitOfMeasureId: o.termUnitOfMeasureId,
        minAmount: amountRange.min ?? 0,
        maxAmount: amountRange.max ?? 0,
        items: o.items,
        isValid: true,
      };
    });

    const latestCostImplications = [...latestLocalContractTerms, ...latestOverlappingContractsTerms];

    setCostImplications((currentCostImplicationState) => {
      const invalidSelectedCostImplications: ICostImplicationGridRow[] = [];

      currentCostImplicationState.forEach((current) => {
        const index = latestCostImplications.findIndex((latest) => latest.itemTermUniqueId === current.itemTermUniqueId);
        if (index === -1) {
          //include current selections, but mark as invalid.
          if (selectedItemTermUniqueIds.includes(current.itemTermUniqueId)) {
            invalidSelectedCostImplications.push({ ...current, isValid: false });
          }
        }
      });
      //set costImplications state to latest implications from API and invalid selected implications
      return [...invalidSelectedCostImplications, ...latestCostImplications];
    });
  }

  function toPricingItemTerm(costImplication: ICostImplicationGridRow): IPricingItemTerm {
    return {
      contract: costImplication.contract,
      items: costImplication.items,
      itemTermUniqueId: costImplication.itemTermUniqueId,
      termTypeId: costImplication.termTypeId,
      termUnitOfMeasureId: costImplication.termUnitOfMeasureId,
      startDate: costImplication.startDate,
      endDate: costImplication.endDate,
    };
  }

  function toCostImplicationGridRow(pricingTerm: IPricingItemTerm): ICostImplicationGridRow {
    const amountRange = getAmountRange(pricingTerm.items.flatMap((i) => i.amount));

    return { ...pricingTerm, isValid: true, minAmount: amountRange.min ?? 0, maxAmount: amountRange.max ?? 0 };
  }

  function handleSave(data: IPricingModalState) {
    props.onSave({
      ...props.initialState,
      uniqueId: props.initialState.uniqueId ?? v4(),
      priceTypeId: watch('priceTypeId'),
      comments: data.comments,
      dateRange: data.dateRange,
      itemTerms: costImplications.filter((ci) => ci.isValid && selectedItemTermUniqueIds.includes(ci.itemTermUniqueId)).map(toPricingItemTerm),
    });
  }

  function costImplicationHasStores(storeSelections: IStoreSelection) {
    return storeSelections.storeGroupIds.length > 0 || storeSelections.storeIds.length > 0;
  }

  function getDistinctStores(storeSelections: IStoreSelection): IStore[] {
    const { storeIds = [], storeGroupIds = [] } = storeSelections;

    if (!referenceData?.stores || !referenceData?.storeGroups) {
      return [];
    }

    // Get all store numbers based on storeGroupIds
    const storeNumbers: number[] = [];
    storeGroupIds.forEach((groupId) => {
      const group = referenceData.storeGroups[groupId];
      if (group) storeNumbers.push(...group.storeNumbers);
    });

    // Add storeIds to storeNumbers
    storeIds.forEach((storeId) => {
      if (!storeNumbers.includes(storeId)) storeNumbers.push(storeId);
    });

    // Filter out duplicate storeNumbers
    const distinctStoreNumbers = Array.from(new Set(storeNumbers));

    // Retrieve store information based on storeNumbers
    const distinctStores: IStore[] = distinctStoreNumbers
      .map((storeNumber) => {
        const store = referenceData.stores[storeNumber];
        return store ? { id: store.id, name: store.name, displayName: store.displayName } : null;
      })
      .filter((store) => store !== null) as IStore[]; // Filter out null entries

    return distinctStores.sort((a, b) => a.displayName.localeCompare(b.displayName));
  }

  // SUB-COMPONENTS
  function StoreIcon(props: { stores: IStoreSelection }) {
    const { stores } = props;

    const hasStores = costImplicationHasStores(stores);

    const iconToDisplay = hasStores ? (
      <MdStore className={css(classes.storeIcon)} />
    ) : (
      <MdOutlineStore className={css(classes.storeIcon, { color: defaultColors.mediumBlueGrey })} />
    );

    return (
      <div className={css(classes.stores)}>
        {iconToDisplay}
        {hasStores && (
          <div className={css(classes.storeCountBadge)}>
            <div className={css(classes.storeCount)}>{getDistinctStores(stores).length}</div>
          </div>
        )}
      </div>
    );
  }

  function StorePopoverContent(props: { stores: IStoreSelection }) {
    const { stores } = props;

    const hasStores = costImplicationHasStores(stores);

    if (!hasStores) return <div>no stores</div>;

    return (
      <>
        {getDistinctStores(stores).map((store) => (
          <div key={store.id}>{store.displayName}</div>
        ))}
      </>
    );
  }

  return (
    <CoreDialog
      id={props.id}
      open={props.open}
      onClose={props.onCancel}
      childWrapperClassName={css({ height: '100%' })}
      className={css({ width: '75%' })}
      classes={{
        root: classes.root,
      }}
    >
      <CoreDialogTitle>{`${isNewForm ? 'Add' : 'Edit'} Pricing`}</CoreDialogTitle>
      <CoreDialogContent classes={{ root: classes.content }}>
        {referenceData && (
          <>
            <div className={classes.form}>
              <div className={classes.formRow}>
                <div className={classes.inputs}>
                  <Controller
                    control={control}
                    name={'priceTypeId'}
                    render={({ field }) => (
                      <Select
                        id={'priceTypeSelect'}
                        label="Price Type"
                        items={priceTypeOptions}
                        onChange={field.onChange}
                        value={field.value || ''}
                        className={classes.priceType}
                        errorMessage={errors.priceTypeId?.message}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={'dateRange'}
                    render={({ field }) => (
                      <DateRangePickerInput
                        id={'PricingDateRange'}
                        label="Select date range"
                        format={'MM/DD/YY'}
                        value={field.value}
                        onChange={field.onChange}
                        errorMessage={errors.dateRange?.message}
                        disabled={isSearching}
                        onAccept={() => handleFindImplications()}
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name={'comments'}
                    render={({ field }) => (
                      <TextInput id={'comments'} label={'Comments'} value={field.value} onChange={field.onChange} className={classes.comments} />
                    )}
                  />
                </div>
              </div>
              <div className={css(classes.formRow, classes.gridRow)}>
                <div className={classes.text}>Select the cost implications that apply.</div>
                <DataGrid
                  id={'costImplicationsGrid'}
                  getRowId={(r: ICostImplicationGridRow) => r.itemTermUniqueId}
                  bodyRowHeight={54}
                  columns={gridColumns}
                  rows={costImplications}
                  isLoading={isSearching}
                  cssOverrides={{
                    root: { width: '90%' },
                  }}
                  getRowClassName={(params) => cx({ [classes.gridRowDisabled]: !params.row.isValid })}
                  hideFooter
                />
              </div>
            </div>
          </>
        )}
      </CoreDialogContent>
      <CoreDialogActions variant={'horizontal'} classes={{ root: classes.actions }}>
        <div>
          <Button id={'cancelBtn'} variant="rounded-sides" disabled={isSearching} classes={{ root: classes.cancelBtnRoot }} onClick={props.onCancel}>
            Cancel
          </Button>
          <Button
            id={'saveBtn'}
            variant="rounded-sides"
            disabled={isSearching}
            classes={{ root: classes.saveBtnRoot }}
            onClick={handleSubmit(onSubmit)}
          >
            Save
          </Button>
        </div>
        <div>
          <Button id={'deleteBtn'} variant="link" disabled={isSearching} classes={{ root: classes.deleteBtnRoot }} onClick={props.onDelete}>
            Delete Pricing
          </Button>
        </div>
      </CoreDialogActions>
    </CoreDialog>
  );
});

export default PricingModal;

const useStyles = tss.create({
  root: {
    '&&': {
      minWidth: '75%',
      height: '100%',
    },
  },
  actions: {
    '&&': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  cancelBtnRoot: {
    backgroundColor: defaultColors.lightGrey,
    margin: '12px',
  },
  saveBtnRoot: {
    backgroundColor: defaultColors.blue,
    color: defaultColors.white,
    margin: '12px',
  },
  deleteBtnRoot: {
    '&&': {
      color: defaultColors.red,
      margin: '12px',
    },
  },
  content: {
    '&&': {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      padding: '10px',
    },
  },
  form: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  formRow: {
    marginBottom: '35px',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  gridRow: {
    width: '100%',
    flexDirection: 'column',
  },
  priceType: {
    width: '150px',
    marginRight: '10px',
    height: '56px',
  },
  text: {
    fontSize: '15px',
    marginBottom: '10px',
    fontWeight: 500,
  },
  inputs: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  comments: {
    width: '300px',
    marginLeft: '10px',
  },
  storesPopoverContent: {
    width: '250px',
    maxHeight: '450px',
  },
  stores: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  storeCount: {
    fontSize: '10px',
    fontWeight: 600,
    color: defaultColors.white,
    marginLeft: '6px',
    marginRight: '6px',
  },
  storeCountBadge: {
    minWidth: '21px',
    maxWidth: '32px',
    backgroundColor: defaultColors.blue,
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    border: `1px solid ${defaultColors.white}`,
    marginLeft: '-10px',
    marginTop: '-10px',
  },
  storeIcon: {
    fontSize: '22px',
    color: defaultColors.darkGrey,
    cursor: 'pointer',
    '&&:hover': {
      backgroundColor: 'unset',
    },
  },
  gridRowDisabled: {
    '&&': {
      backgroundColor: alpha(defaultColors.red, 0.5),
      textDecoration: 'line-through',
      '&:hover': {
        backgroundColor: alpha(defaultColors.red, 0.5),
        textDecoration: 'line-through',
      },
    },
  },
});
